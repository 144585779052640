var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isHorizontal
    ? _c(
        "div",
        {
          staticClass: "plan-box-horizontal plan-box",
          style: { height: _vm.localBodyHeight + "px" },
        },
        [
          _c(
            "div",
            {
              ref: "planHeaderHorizontal",
              staticClass: "plan-header-horizontal",
              attrs: { id: "planHeaderHorizontal" },
            },
            [
              _c(
                "div",
                _vm._l(_vm.plans, function (view, v) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: view.show,
                          expression: "view.show",
                        },
                      ],
                      key: `day-header-${v}`,
                      staticClass: "day-header-item",
                      class: {
                        isCurrent: _vm.isCurrent(view),
                        "mobile-day-header-item":
                          _vm.$vuetify.breakpoint.smAndDown,
                      },
                      style: _vm.getDayHeaderStyle(view),
                    },
                    [_c("day-header", { attrs: { day: view } })],
                    1
                  )
                }),
                0
              ),
            ]
          ),
          _c(
            "perfect-scrollbar",
            {
              staticClass: "plan-box-horizontal-container",
              staticStyle: { "overflow-x": "auto" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "plan-body-horizontal plan-body",
                  style: { zoom: _vm.zoom },
                },
                _vm._l(_vm.plans, function (view, v) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: view.show,
                          expression: "view.show",
                        },
                      ],
                      key: `day-${v}`,
                      staticClass: "section-cards",
                      class: {
                        past: view.past,
                        "reduce-brightness": _vm.isReduceBrightnessOfPastDays,
                      },
                      style: {
                        height: _vm.cardContainerHeightPx,
                        display: "flex",
                      },
                    },
                    _vm._l(view.dayObjects, function (dayObject, i) {
                      return _c(
                        "div",
                        {
                          key: `day-object-${v + "-" + i}`,
                          staticClass: "section-cards__box",
                          style: { width: _vm.cardWidthPx },
                        },
                        [
                          _c("section-card", {
                            key: `day-object-h-${v + "-" + i}-${
                              dayObject.dayObjectId
                            }`,
                            attrs: {
                              dayObject: dayObject,
                              showDetails: _vm.showDetails,
                              enableDragDrop: "",
                              container: ".plan-body-horizontal.plan-body",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }