var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "list-view",
      class: _vm.$vuetify.breakpoint.smAndDown ? "pt-0" : "pt-4",
      attrs: { color: "#ffffff" }
    },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _c(
                "v-slide-group",
                {
                  staticClass: "py-2",
                  attrs: { "center-active": "" },
                  model: {
                    value: _vm.localSlideGroupModel,
                    callback: function($$v) {
                      _vm.localSlideGroupModel = $$v
                    },
                    expression: "localSlideGroupModel"
                  }
                },
                [
                  _c(
                    "v-slide-item",
                    { key: 0 },
                    [
                      _c(
                        "v-menu",
                        {
                          key: _vm.currentSubjectId,
                          attrs: {
                            transition: "scroll-y-transition",
                            "offset-y": "",
                            "max-height": "500"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              outlined: "",
                                              rounded: "",
                                              small: "",
                                              color: "primary"
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ml-1",
                                            style: {
                                              color:
                                                _vm.currentSelectedClassColor
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.localClassName))]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-2",
                                            attrs: { small: "" }
                                          },
                                          [_vm._v("fal fa-chevron-down")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2900620997
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: {
                                    color: "primary",
                                    "aria-label": _vm.$t("classesLabel")
                                  }
                                },
                                [
                                  _vm._l(_vm.localClassItems, function(
                                    lesson,
                                    index
                                  ) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: index,
                                        class: {
                                          "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                            _vm.currentSubjectId == lesson.value
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.changeClass(
                                              lesson.value,
                                              true
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "drop-down-list py-2 px-4 rounded-lg",
                                            class: _vm.highContrastMode
                                              ? "contrast-view"
                                              : "",
                                            style: {
                                              color: lesson.textColor,
                                              backgroundColor: lesson.color
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(_vm._s(lesson.text))
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _vm.hasSharedClasses
                                    ? _c(
                                        "div",
                                        [
                                          _c("v-divider", {
                                            staticClass: "mt-5"
                                          }),
                                          _c(
                                            "v-subheader",
                                            { staticClass: "justify-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("sharedClassesLabel")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("v-divider", {
                                            staticClass: "mb-5"
                                          }),
                                          _vm._l(_vm.sharedClasses, function(
                                            sharedClass,
                                            index
                                          ) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: index,
                                                on: {
                                                  click: function($event) {
                                                    return _vm.changeClass(
                                                      sharedClass.classId,
                                                      true
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "drop-down-list py-2 px-4 rounded-lg",
                                                    class: _vm.highContrastMode
                                                      ? "contrast-view"
                                                      : "",
                                                    style: {
                                                      color: "white",
                                                      backgroundColor:
                                                        sharedClass.classColor
                                                    }
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          sharedClass.email +
                                                            " - " +
                                                            sharedClass.text
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isThereSelectedClass && !_vm.$currentUser.isStudent
                    ? _c(
                        "v-slide-item",
                        { key: 1 },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scroll-y-transition",
                                "offset-y": "",
                                "max-height": "500"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  outlined: "",
                                                  rounded: "",
                                                  small: "",
                                                  color: "primary"
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "ml-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.selectedUnits)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { small: "" }
                                              },
                                              [_vm._v("fal fa-chevron-down")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1353191119
                              )
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _vm.userMode === "T"
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.addOrEditUnits({})
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("fal fa-circle-plus")]
                                          ),
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "drop-down-list" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("addUnitLabel"))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.initialize(undefined, true)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "drop-down-list" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("allUnitsLabel")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.units, function(unit, index) {
                                    return _c(
                                      "v-hover",
                                      { key: index },
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.filterUnits(unit)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "drop-down-list" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(unit.num) +
                                                        " - " +
                                                        _vm._s(unit.title)
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm.userMode === "T"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      icon: "",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.addOrEditUnits(
                                                          unit
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("fal fa-pencil")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.userMode === "T"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      color: "error",
                                                      icon: "",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.deleteUnit(
                                                          unit,
                                                          false
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "fal fa-trash-alt"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c("v-text-field", {
            staticClass: "mx-3",
            attrs: {
              clearable: "",
              "prepend-inner-icon": "fal fa-search",
              label: _vm.$t("searchLessonTitleLabel")
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        : _vm._e(),
      !_vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c(
                            "v-menu",
                            {
                              key: _vm.currentSubjectId,
                              attrs: {
                                transition: "scroll-y-transition",
                                "offset-y": "",
                                "max-height": "500"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mx-2",
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  elevation: "0",
                                                  color: "primary",
                                                  text: ""
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ml-1",
                                                style: {
                                                  color:
                                                    _vm.currentSelectedClassColor
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.localClassName)
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2224631982
                              )
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      attrs: {
                                        color: "primary",
                                        "aria-label": _vm.$t("classesLabel")
                                      }
                                    },
                                    [
                                      _vm._l(_vm.localClassItems, function(
                                        lesson,
                                        index
                                      ) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            class: {
                                              "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                _vm.currentSubjectId ==
                                                lesson.value
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.changeClass(
                                                  lesson.value,
                                                  true
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "drop-down-list py-2 px-4 rounded-lg",
                                                class: _vm.highContrastMode
                                                  ? "contrast-view"
                                                  : "",
                                                style: {
                                                  color: lesson.textColor,
                                                  backgroundColor: lesson.color
                                                }
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(_vm._s(lesson.text))
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      _vm.hasSharedClasses
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-divider", {
                                                staticClass: "mt-5"
                                              }),
                                              _c(
                                                "v-subheader",
                                                {
                                                  staticClass: "justify-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "sharedClassesLabel"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("v-divider", {
                                                staticClass: "mb-5"
                                              }),
                                              _vm._l(
                                                _vm.sharedClasses,
                                                function(sharedClass, index) {
                                                  return _c(
                                                    "v-list-item",
                                                    {
                                                      key: index,
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.changeClass(
                                                            sharedClass.classId,
                                                            true
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "drop-down-list py-2 px-4 rounded-lg",
                                                          class: _vm.highContrastMode
                                                            ? "contrast-view"
                                                            : "",
                                                          style: {
                                                            color: "white",
                                                            backgroundColor:
                                                              sharedClass.classColor
                                                          }
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                sharedClass.email +
                                                                  " - " +
                                                                  sharedClass.text
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.isThereSelectedClass &&
                          !_vm.$currentUser.isStudent
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    transition: "scroll-y-transition",
                                    "offset-y": "",
                                    "max-height": "500"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mx-2",
                                                    attrs: {
                                                      outlined: "",
                                                      dense: "",
                                                      elevation: "0",
                                                      color: "primary",
                                                      text: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.selectedUnits)
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4196695012
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _vm.userMode === "T"
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.addOrEditUnits({})
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { small: "" }
                                                },
                                                [_vm._v("fal fa-circle-plus")]
                                              ),
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass: "drop-down-list"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("addUnitLabel")
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.initialize(
                                                undefined,
                                                true
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "drop-down-list" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("allUnitsLabel")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._l(_vm.units, function(unit, index) {
                                        return _c("v-hover", {
                                          key: index,
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var hover = ref.hover
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.filterUnits(
                                                              unit
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            staticClass:
                                                              "drop-down-list"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mt-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    unit.num
                                                                  ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      unit.title
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        hover &&
                                                        _vm.userMode === "T"
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                                attrs: {
                                                                  icon: "",
                                                                  small: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.addOrEditUnits(
                                                                      unit
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fal fa-pencil"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        hover &&
                                                        _vm.userMode === "T"
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                  icon: "",
                                                                  small: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteUnit(
                                                                      unit,
                                                                      false
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fal fa-trash-alt"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                          _c("v-text-field", {
                            staticClass: "mx-2",
                            staticStyle: { "max-width": "250px" },
                            attrs: {
                              clearable: "",
                              outlined: "",
                              dense: "",
                              "single-line": "",
                              "hide-details": "",
                              "prepend-inner-icon": "fal fa-search",
                              label: _vm.$t("searchLessonTitleLabel")
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { ref: "scrollContainer", staticClass: "scroll-container" },
        [
          _c("v-data-table", {
            attrs: {
              loading: _vm.initialLoad,
              "disable-pagination": "",
              headers: _vm.localHeaders,
              "hide-default-footer": "",
              items: _vm.infiniteScrollList,
              "fixed-header": "",
              search: _vm.search,
              height: _vm.$vuetify.breakpoint.smAndDown
                ? "calc(100vh - 220px)"
                : "calc(100vh - 160px)"
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function(ref) {
                  var items = ref.items
                  var headers = ref.headers
                  return [
                    _c("div", {
                      ref: "topSentinel",
                      staticClass: "TopSentinel"
                    }),
                    _c(
                      "tbody",
                      { ref: "listContainer", staticClass: "list-container" },
                      [
                        _vm.canLoadMoreTop &&
                        !_vm.initialLoad &&
                        _vm.pageNumber !== 0 &&
                        (_vm.search === null || _vm.search === "")
                          ? _c("tr", [
                              _c("td"),
                              _c("td"),
                              _c("td"),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    style: {
                                      "margin-right": _vm.$vuetify.breakpoint
                                        .smAndDown
                                        ? "-250px"
                                        : "250px"
                                    },
                                    attrs: {
                                      align: "center",
                                      justify: "center"
                                    }
                                  },
                                  [
                                    _vm.isLoadingMoreTop
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            color: "primary",
                                            indeterminate: ""
                                          }
                                        })
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { "font-size": "12px" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "scrollUpToLoadMoreLabel"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("fal fa-arrow-up")]
                                            )
                                          ],
                                          1
                                        )
                                  ],
                                  1
                                )
                              ]),
                              _c("td", {
                                style: _vm.$vuetify.breakpoint.smAndDown
                                  ? "width: 77%;"
                                  : ""
                              })
                            ])
                          : _vm._e(),
                        _vm._l(items, function(item, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              ref: "listRow",
                              refInFor: true,
                              class: {
                                past: _vm.isDayPast(item),
                                "reduce-brightness":
                                  _vm.isReduceBrightnessOfPastDays
                              },
                              style: {
                                backgroundColor: _vm.getDateBgColor(item.date),
                                color: _vm.getDateTextColor(item.date)
                              },
                              attrs: {
                                tabindex: "0",
                                width: "10%",
                                id: _vm.lessonUniqueIdentifier(item),
                                role: "row"
                              },
                              on: {
                                contextmenu: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.showActions($event, item)
                                },
                                keydown: [
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "arrow-down",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.downArrowClick(item)
                                  },
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "arrow-up",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.upArrowClick(item)
                                  }
                                ],
                                mouseenter: function($event) {
                                  return _vm.hoverEnterEdit(item)
                                },
                                mouseleave: _vm.hoverExitEdit,
                                focus: function($event) {
                                  return _vm.hoverEnterEdit(item)
                                },
                                blur: _vm.hoverExitEdit,
                                click: function($event) {
                                  return _vm.openLessonViewMode(item, false)
                                },
                                dblclick: function($event) {
                                  return _vm.openLessonEditMode(item, false)
                                }
                              }
                            },
                            [
                              _c(
                                "td",
                                {
                                  style: _vm.$vuetify.breakpoint.xsOnly
                                    ? "width: 3%;"
                                    : "width: 3%;"
                                },
                                [_c("span", [_vm._v(_vm._s(item.lessonNum))])]
                              ),
                              _c(
                                "td",
                                {
                                  style: _vm.$vuetify.breakpoint.smAndDown
                                    ? "width: 30%"
                                    : "width: 7%;"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.stringifyDate(item.date, true)
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  style: _vm.$vuetify.breakpoint.smAndDown
                                    ? "width: 10%"
                                    : "width: " +
                                      (_vm.units.length > 0 ? "10%" : "5%") +
                                      ";"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center"
                                      },
                                      on: {
                                        keydown: [
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "down",
                                                40,
                                                $event.key,
                                                ["Down", "ArrowDown"]
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "up",
                                                38,
                                                $event.key,
                                                ["Up", "ArrowUp"]
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _vm.units.length > 0
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                rounded: "",
                                                "offset-y": ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var attrs = ref.attrs
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                class:
                                                                  +item.unitId >
                                                                    0 &&
                                                                  !_vm.isColorReadable(
                                                                    _vm.getUnitSelectBGColor(
                                                                      item
                                                                    )
                                                                  )
                                                                    ? "pb-border"
                                                                    : "",
                                                                style: {
                                                                  color: _vm.getUnitSelectColor(
                                                                    item
                                                                  )
                                                                },
                                                                attrs: {
                                                                  depressed: "",
                                                                  rounded: "",
                                                                  "x-small": "",
                                                                  color:
                                                                    +item.unitId >
                                                                    0
                                                                      ? _vm.getUnitSelectBGColor(
                                                                          item
                                                                        )
                                                                      : "#b5bac1"
                                                                },
                                                                on: {
                                                                  focus: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.hoverEnterEdit(
                                                                      item
                                                                    )
                                                                  },
                                                                  blur:
                                                                    _vm.hoverExitEdit
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.unitNum ||
                                                                    _vm.$t(
                                                                      "unitPlusLabel"
                                                                    )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      class: {
                                                        "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                          item.unitId == 0
                                                      },
                                                      attrs: { link: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectUnit(
                                                            item,
                                                            { id: 0 }
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("noneLabel")
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _vm._l(_vm.units, function(
                                                    unit
                                                  ) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        key: unit.unitId,
                                                        class: {
                                                          "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                            item.unitId ==
                                                            unit.id
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.selectUnit(
                                                              item,
                                                              unit
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                unit.num ||
                                                                  unit.unitNum
                                                              ) +
                                                                " - " +
                                                                _vm._s(
                                                                  unit.title ||
                                                                    unit.unitTitle
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  })
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  style: _vm.$vuetify.breakpoint.smAndDown
                                    ? "width: 50%"
                                    : "width: 50%;"
                                },
                                [
                                  _vm.isUntitledLesson(item)
                                    ? _c("div", [
                                        _vm.hasText(_vm.lessonTextField(item))
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.firstHundredChar(
                                                    _vm.lessonTextField(item)
                                                  )
                                                )
                                              )
                                            ])
                                          : _vm.hasText(item.linkedLessonTitle)
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(item.linkedLessonTitle)
                                              )
                                            ])
                                          : _vm.hasText(item.linkedLessonText)
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.firstHundredChar(
                                                    item.linkedLessonText
                                                  )
                                                )
                                              )
                                            ])
                                          : _vm.hasText(item.unitTitle)
                                          ? _c("span", [
                                              _vm._v(_vm._s(item.unitTitle))
                                            ])
                                          : item.unitId > 0 &&
                                            _vm.hasText(
                                              _vm.unitSectionsTextField(item)
                                            )
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.firstHundredChar(
                                                    _vm.unitSectionsTextField(
                                                      item
                                                    )
                                                  )
                                                )
                                              )
                                            ])
                                          : item.linkedUnit &&
                                            _vm.hasText(
                                              item.linkedUnit.unitTitle
                                            )
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.linkedUnit.unitTitle
                                                )
                                              )
                                            ])
                                          : item.linkedUnitId > 0 &&
                                            _vm.hasText(
                                              _vm.linkedUnitSectionsTextField(
                                                item
                                              )
                                            )
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.firstHundredChar(
                                                    _vm.linkedUnitSectionsTextField(
                                                      item
                                                    )
                                                  )
                                                )
                                              )
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("untitledLessonsLabel")
                                                )
                                              )
                                            ])
                                      ])
                                    : _vm.isEmptyLesson(item)
                                    ? _c(
                                        "span",
                                        {
                                          style: {
                                            color:
                                              _vm.$vuetify.theme.currentTheme
                                                .info
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("emptyLessonLabel"))
                                          )
                                        ]
                                      )
                                    : _c("strong", [
                                        _vm._v(_vm._s(item.lessonTitle))
                                      ])
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  style: _vm.$vuetify.breakpoint.smAndDown
                                    ? "width: 10%"
                                    : "width: 10%;"
                                },
                                [
                                  _vm.showLessonActions(item)
                                    ? _c(
                                        "div",
                                        {
                                          attrs: {
                                            align: "center",
                                            justify: "center"
                                          }
                                        },
                                        [
                                          _c("lesson-actions", {
                                            attrs: {
                                              type: "custom",
                                              input: _vm.getLessonInput(item)
                                            },
                                            on: {
                                              focus: function($event) {
                                                return _vm.hoverEnterEdit(item)
                                              },
                                              blur: _vm.hoverExitEdit
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "customButton",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: !_vm
                                                                .$currentUser
                                                                .isStudent,
                                                              expression:
                                                                "!$currentUser.isStudent"
                                                            }
                                                          ],
                                                          staticStyle: {
                                                            cursor: "pointer"
                                                          },
                                                          attrs: {
                                                            size: "20",
                                                            color:
                                                              _vm.lessonUniqueIdentifier(
                                                                item
                                                              ) ===
                                                                _vm.selectedItem ||
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .smAndDown
                                                                ? ""
                                                                : "transparent"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " fas fa-ellipsis-vertical "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        }),
                        _vm.canLoadMore &&
                        !_vm.initialLoad &&
                        (_vm.search === null || _vm.search === "")
                          ? _c("tr", [
                              _c("td"),
                              _c("td"),
                              _c("td"),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    style: {
                                      "margin-right": _vm.$vuetify.breakpoint
                                        .smAndDown
                                        ? "-250px"
                                        : "250px"
                                    },
                                    attrs: {
                                      align: "center",
                                      justify: "center"
                                    }
                                  },
                                  [
                                    _vm.isLoadingMore
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            color: "primary",
                                            indeterminate: ""
                                          }
                                        })
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { "font-size": "12px" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "scrollDownToLoadMoreLabel"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("fal fa-arrow-down")]
                                            )
                                          ],
                                          1
                                        )
                                  ],
                                  1
                                )
                              ]),
                              _c("td", {
                                style: _vm.$vuetify.breakpoint.smAndDown
                                  ? "width: 77%;"
                                  : ""
                              })
                            ])
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm.isEmpty(_vm.infiniteScrollList) && !_vm.initialLoad
                      ? _c("tbody", [
                          _c(
                            "tr",
                            { staticClass: "v-data-table__empty-wrapper" },
                            [
                              _c("td", { attrs: { colspan: headers.length } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("noDataAvailableLabel")) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _c("div", { ref: "sentinel", staticClass: "sentinel" })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }