
import SectionCard from '@/components/plans/card/SectionCard.vue';
import DayCount from '@/components/plans/common/DayCount.vue';
import DayHeader from '@/components/plans/common/DayHeader.vue';
import PlansViewMixin from '@/mixins/plans-view-mixin';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import WebUtils from '@/utils/web-utils';
import ld from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const settings = namespace('settings');
@Component({
  mixins: [PlansViewMixin],
  components: {
    SectionCard,
    DayHeader,
    DayCount
  }
})
export default class VerticalView extends Vue {
  settings!: any;
  viewType!: string;
  expandNotesTodo!: boolean;
  currentPlans!: any;
  currentCyclePlans!: any;
  orientationSettings!: any;
  userMode!: string;
  showDetails!: boolean;
  browserHeight!: number;
  browserWidth!: number;
  enabledDaysCount!: any;
  isVertical!: boolean;
  isEqual!: boolean;
  isAdjustToFit!: boolean;
  boxSize!: number;
  zoom!: number;
  maxElements!: number;
  bodyHeight!: number;
  drawerRight!: boolean;
  isReduceBrightnessOfPastDays!: boolean;
  isArrayNotEmpty!: (a: any[]) => boolean;
  getStyle!: (view: any) => any;
  isCurrent!: (view: any) => any;
  heightAdjusted = false;
  leaving = false;
  viewKey!: string;

  @settings.Getter('showCount')
  showCount!: boolean;

  get isNotEmpty() {
    return CommonUtils.isNotEmpty;
  }

  get plans() {
    const plans = ld.cloneDeep(this.localPlans) || {};
    delete plans.dayCount;
    Object.keys(plans).forEach(key => {
      if (!key.startsWith('day')) {
        delete plans[key];
      } else {
        plans[key].past = DateTimeUtils.isPast(plans[key].date);
      }
    });
    return plans;
  }

  get ezPlansLessons() {
    const plans = ld.cloneDeep(this.plans);
    Object.keys(plans).forEach(key => {
      if (key.startsWith('day')) {
        const day = plans[key];
        const dayObjects = day?.dayObjects || [];
        day.dayObjects = dayObjects.filter((o: any) => o.type === 'L');
      }
    });
    return plans;
  }

  get ezPlansEvents() {
    const plans = ld.cloneDeep(this.plans);
    Object.keys(plans).forEach(key => {
      if (key.startsWith('day')) {
        const day = plans[key];
        const dayObjects = day?.dayObjects || [];
        day.dayObjects = dayObjects.filter((o: any) => o.type !== 'L');
      }
    });
    return plans;
  }

  get width() {
    if (this.viewType === 'C') {
      return (100 / this.currentCyclePlans.dayCount) + '%';
    } else {
      return (100 / this.enabledDaysCount) + '%';
    }
  }

  get localPlans() {
    if (this.viewType === 'C') {
      return this.currentCyclePlans;
    } else {
      return this.currentPlans;
    }
  }

  get ezElementStyle() {
    const style: any = { width: 'auto' };
    if (!this.isAdjustToFit && this.showDetails) {
      style.height = this.boxSize + 'px';
    }
    return style;
  }

  get planBoxHeight() {
    let offset = this.getPlanHeaderBottomOffset() || 0;
    if (this.$vuetify.breakpoint.smAndDown) {
      offset += 56;
    }
    if (CommonUtils.hasText(this.viewKey) && offset) {
      return `${this.browserHeight - offset - 1}px`
    } else {
      return `calc(100vh - ${(104 + (this.showCount ? 30 : 0))}px)`;
    }
  }

  getPlanHeaderBottomOffset() {
    try {
      const element = document.querySelector('#planHeaderVertical') as HTMLElement;
      const rect = element.getBoundingClientRect();
      return rect.top + element.offsetHeight;
    } catch (e) {
    }
  }

  updated() {
    WebUtils.initMathJax();
  }
}
