var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plans" },
    [
      _vm.viewType == "M"
        ? _c("month-view")
        : _vm.viewType == "L"
        ? _c("list-view")
        : _c("week-or-day-view"),
      _c("pb-confirm", { ref: "confirm" }),
      _vm.hasSchoolInvites
        ? _c("manage-user-schools", {
            model: {
              value: _vm.manageSchools,
              callback: function($$v) {
                _vm.manageSchools = $$v
              },
              expression: "manageSchools"
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "plans-spacer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }