

















import { PlanbookStorage } from '@/common/planbook-storage';
import { UserSession } from '@/common/user-session';
import Confirm from '@/components/core/Confirm.vue';
import ManageUserSchools from '@/components/settings/ManageUserSchools.vue';
import ListView from '@/components/plans/views/ListView.vue';
import MonthView from '@/components/plans/views/MonthView.vue';
import WeekOrDayView from '@/components/plans/views/WeekOrDayView.vue';
import vuetify from '@/plugins/vuetify';
import CommonUtils from '@/utils/common-utils';
import ld from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import os from '@/services/overview-services';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import Teachers from '@/store/modules/teachers';
import LessonServices from '@/services/lesson-services';
import SchoolYearMixin from '@/mixins/school-year-mixin';

const plans = namespace('plans');
const settings = namespace('settings');
const teachers = namespace('teachers');
const templates = namespace('templates');
const events = namespace('events');
const standards = namespace('standards');

@Component({
  mixins: [PageLifeCycleMixin, SchoolYearMixin],
  components: {
    ListView,
    MonthView,
    WeekOrDayView,
    ManageUserSchools
  }
})
export default class Plans extends Vue {
  @plans.State
  viewType!: string;

  @settings.State
  userMode!: string;

  @settings.Getter
  isPaymentNeeded!: boolean;

  @settings.Getter
  isSchoolSuspended!: boolean;

  @plans.State
  cyclePage!: number;

  @plans.Getter('getCurrentPlans')
  currentPlans!: any;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter
  isCycleSchedule!: boolean;

  @settings.Getter
  isSubscriptionExpiring!: boolean;

  @settings.Getter
  isNewYearNeeded!: boolean;

  @settings.Getter
  paidThroughDateDiff!: number;

  @teachers.Getter
  isCurrentTeacherCycleSchedule!: number;

  @plans.Action
  loadPlans!: (param?: any) => Promise<any>;

  @settings.Getter
  hasSchoolInvites!: boolean;

  @plans.Action
  reloadPlans!: (param?: any) => Promise<any>;

  @plans.Action
  init!: (param?: any) => Promise<any>;

  @plans.Action
  changeTerm!: (param?: any) => Promise<any>;

  changePlans!: (param?: any) => Promise<any>;

  @plans.Action
  changeCyclePage!: (param?: number) => Promise<any>;

  @plans.Action
  loadCycleSchedule!: (viewType?: string) => Promise<any>;

  @plans.Action
  changeViewType!: (viewType?: string) => Promise<any>;

  @plans.Mutation
  setRemindersFloatingIcon!: (value: string) => void;

  @settings.Mutation
  setNewUser!: (value: boolean) => void;

  editSchoolYear!: (modal: boolean, item: any) => void;

  @plans.Getter('getCyclePages')
  cyclePages!: any[];

  @settings.Getter('getViewType')
  displayViewType!: string;

  @templates.Action
  loadTemplates!: (param?: any) => Promise<any>;

  @standards.Action
  loadStandards!: (params?: any) => Promise<any>;

  @events.Action
  adminEventShift!: (param?: any) => Promise<any>;

  @events.Action
  getEventNotificationsById!: (param?: any) => Promise<any>;

  @plans.State
  adminEvents!: Array<any>;

  @settings.State
  newUser!: true;

  @plans.State
  parentChange!: boolean;

  @settings.Action
  save!: (param?: any) => Promise<any>;

  @settings.State
  studentTeachers!: Array<any>;

  manageSchools = false;

  $refs!: {
    confirm: Confirm,
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get isArrayNotEmpty() {
    return CommonUtils.isNotEmpty;
  }

  get editLink() {
    return this.$store.state.lessons.editLink;
  }

  set editLink(value: any) {
    this.$store.commit('lessons/setEditLink', value);
  }

  get viewKey() {
    return this.$store.state.plans.viewKey;
  }

  set viewKey(value: any) {
    this.$store.commit('plans/setViewKey', value);
  }

  get isMobile() {
    return vuetify.framework.breakpoint.mobile;
  }

  get currentTeacherId(): number {
    if (this.userMode === 'T') {
      return +this.$store.state.settings.userId;
    } else {
      return +this.$store.state.settings.currentTeacherId;
    }
  }

  get isPlansListView() {
    return this.localPage === 'plans' && this.viewType === 'L';
  }

  get firstLoad() {
    return this.$store.state.settings.firstLoad;
  }

  set firstLoad(value: boolean) {
    this.$store.commit('settings/setFirstLoad', value);
  }

  async fetchPlans() {
    CommonUtils.showLoading();
    return Promise.resolve().then(() => {
      if ((this.isCycleSchedule || this.isCurrentTeacherCycleSchedule) && !this.$currentUser.isStudent) {
        return this.loadCycleSchedule();
      }
      return Promise.resolve();
    }).then(() => {
      return this.init();
    }).then(() => {
      const promises: Array<Promise<any>> = [];
      promises.push(this.loadPlans());
      if (!this.$currentUser.isStudent) {
        promises.push(this.loadTemplates());
        promises.push(this.loadStandards());
      }
      return Promise.allSettled(promises);
    }).then(() => {
      return this.doAfterFetchPlans();
    }).then(() => {
      this.setRemindersFloatingIcon('fal fa-angle-left');
      if (PlanbookStorage.get('reminders')) {
        this.$eventBus.$emit('openSubPage', {
          type: 'reminder',
          width: 350
        });
        this.setRemindersFloatingIcon('fal fa-angle-right');
      }
    }).finally(() => {
      CommonUtils.hideLoading();
      this.$eventBus.$emit('plansLoaded');
      this.$eventBus.$emit('resize');
      if (PlanbookStorage.get('unitViewLessonsParams')) {
        this.$store.commit('plans/setViewType', PlanbookStorage.get('unitViewLessonsParams').viewType);
      }
    });
  }

  async doAfterFetchPlans() {
    this.$nextTick(() => {
      this.viewKey = CommonUtils.generateUUID();
    });
    CommonUtils.hideLoading();
    if (this.newUser) {
      this.setNewUser(false);
      CommonUtils.showLoading();
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        os.start();
      }).finally(() => {
        CommonUtils.hideLoading();
      });
      return Promise.resolve();
    } else if (CommonUtils.isNotEmpty(this.adminEvents) && this.$currentUser.isTeacher) {
      return Promise.resolve().then(() => {
        if (this.isCycleSchedule) {
          CommonUtils.showLoading();
          return this.adminEventShift({ shiftLessons: true });
        } else {
          return this.$refs.confirm.confirm({
            title: this.$t('noSchoolEventLabel'),
            text: this.$t('adminShiftEventMsg'),
            option1ButtonAlternativeText: this.$t('shiftLessonsLabel'),
            option2ButtonAlternativeText: this.$t('doNotShiftLessonsLabel')
          }).then(result => {
            if (result === 1) {
              CommonUtils.showLoading();
              return this.adminEventShift({ shiftLessons: true });
            } else if (result === 2) {
              CommonUtils.showLoading();
              return this.adminEventShift({ shiftLessons: false });
            } else {
              return Promise.resolve();
            }
          });
        }
      }).then(async () => {
        CommonUtils.showLoading();
        if (this.parentChange) {
          return this.save({ parentChange: 'Y' }).then(() => {
            CommonUtils.hideLoading();
            return this.reloadPlans();
          });
        } else {
          return Promise.resolve();
        }
      }).finally(() => {
        CommonUtils.hideLoading();
      });
    } else if (this.parentChange && this.$currentUser.isTeacher) {
      CommonUtils.showLoading();
      return this.save({ parentChange: 'Y' }).then(() => {
        CommonUtils.hideLoading();
        return this.reloadPlans();
      }).then(() => {
        this.$snotify.success(this.$t('schoolYearUpdateMsg2') as string, this.$t('plansRefreshedLabel') as string, { timeout: 10000 });
        return Promise.resolve();
      });
    } else if (this.firstLoad) {
      return Promise.resolve().then(() => {
        if (this.$currentUser.isStudent && CommonUtils.hasText(this.localStudentNotes)) {
          return this.$refs.confirm.alert({
            title: this.$t('viewAnnouncementsLabel'),
            text: this.localStudentNotes,
            option1ButtonAlternativeText: this.$t('okLabel')
          });
        }
        return Promise.resolve({});
      }).then(async () => {
        if (this.isSchoolSuspended) {
          return this.$refs.confirm.alert({
            title: this.$t('accountSuspendedLabel'),
            text: this.$t('accountSuspendedMsg'),
            option1ButtonAlternativeText: this.$t('closeLabel')
          });
        } else if (this.isPaymentNeeded) {
          this.$eventBus.$emit('subscriptionExpired');
        } else if (this.isSubscriptionExpiring) {
          this.$eventBus.$emit('subscriptionExpiring', this.paidThroughDateDiff);
        } else if (this.isNewYearNeeded) {
          return this.$refs.confirm.confirm({
            title: this.$t('addASchoolYearLabel'),
            text: this.$t('newYearNeedMsg'),
            option1ButtonAlternativeText: this.$t('addASchoolYearLabel'),
            cancelButtonText: this.$t('cancelAddSchoolYearLabel')
          }).then((result: any) => {
            if (result === 1) {
              this.editSchoolYear(true, null);
            }
            return Promise.resolve({});
          });
        }
        return Promise.resolve({});
      }).finally(() => {
        this.firstLoad = false;
      })
    }
    return Promise.resolve();
  }

  get localStudentNotes() {
    let text = '';
    try {
      if (this.$currentUser.isStudent) {
        if (this.$currentUser.id > 0) {
          if (CommonUtils.isNotEmpty(this.studentTeachers)) {
            this.studentTeachers.forEach(t => {
              const name = Teachers.getDisplayName(t);
              if (CommonUtils.hasText(t.studentNotes)) {
                text += `<div>
              <span class="font-weight-bold">${name}: </span>
              <div class="d-inline-block">${t.studentNotes}</div>  
            </div>`
              }
            })
          }
        } else if (this.currentTeacherId > 0) {
          const settings = this.$store.state.settings.settings[this.currentTeacherId];
          text += settings?.accountSettings?.studentNotes || this.$currentUser.settings.accountSettings.studentNotes;
        } else {
          text += this.$currentUser.settings.accountSettings.studentNotes;
        }
      }
    } catch (e) {
    }
    return text;
  }

  @Watch('isMobile')
  isViewSizeChange() {
    this.changeViewType(this.displayViewType);
  }

  openLink(a: HTMLAnchorElement, dayObject: any) {
    const url = a.href;
    const downloadForm = document.getElementById('downloadForm') as HTMLFormElement;
    if (CommonUtils.hasText(url)) {
      window.open(url, '_blank');
    } else {
      downloadForm.innerHTML = '';
      const attachmentName = document.createElement('input');
      attachmentName.setAttribute('name', 'attachmentName');
      attachmentName.setAttribute('value', a.text);
      downloadForm.appendChild(attachmentName);

      const teacherId = document.createElement('input');
      teacherId.setAttribute('name', 'teacherId');
      teacherId.setAttribute('value', dayObject.teacherId);
      downloadForm.appendChild(teacherId);

      const collaborateSubjectId = document.createElement('input');
      collaborateSubjectId.setAttribute('name', 'collaborateSubjectId');
      collaborateSubjectId.setAttribute('value', dayObject.collaborateSubjectId);
      downloadForm.appendChild(collaborateSubjectId);

      const accessToken = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-ACCESS-TOKEN');
      accessToken.setAttribute('value', UserSession.getAccessToken());
      downloadForm.appendChild(accessToken);

      const yearId = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-CLIENT-YEAR-ID');
      accessToken.setAttribute('value', UserSession.getCurrentYearIdAsString());
      downloadForm.appendChild(yearId);

      downloadForm.submit();
    }
  }

  onSectionCardContextMenu(e: any) {
    const event = e.event;
    const rawDayObject = ld.cloneDeep(e.dayObject.rawDayObject);
    const type = rawDayObject.type;
    if (this.$currentUser.isTeacher) {
      if (type === 'L') {
        if (CommonUtils.hasNoText(rawDayObject.googleId)) {
          const excludes = LessonServices.excludedLessonActions(rawDayObject, this.userMode, this.localPage);
          this.$eventBus.$emit('actions', {
            type: 'lesson',
            event,
            input: {
              mode: 'P',
              loadData: true,
              data: rawDayObject,
              exclude: rawDayObject.collaborateSubjectId > 0 ? excludes : [],
              lessonListsView: rawDayObject.type === 'E' ? undefined : this.isPlansListView
            }
          });
        }
      } else if (type === 'E') {
        if (rawDayObject.readonly) return;
        if (CommonUtils.hasText(rawDayObject.googleId)) {
          this.$eventBus.$emit('actions', {
            type: 'googleEvent',
            event,
            input: {
              mode: 'P',
              loadData: true,
              data: rawDayObject
            }
          });
        } else if (rawDayObject.schoolId === 0 && rawDayObject.districtId === 0) {
          this.$eventBus.$emit('actions', {
            type: 'event',
            event,
            input: {
              mode: 'P',
              loadData: true,
              data: rawDayObject
            }
          });
        }
      }
    }
  }

  onSectionCardClicked(e: any) {
    const event = e.event;
    const dayObject = e.dayObject;
    const target = event.target;
    const rawDayObject = ld.cloneDeep(e.dayObject.rawDayObject);
    if (rawDayObject.type === 'E' && rawDayObject.readonly) return;
    if (rawDayObject.type === 'E' && CommonUtils.hasText(rawDayObject.googleId)) {
      if (this.$currentUser.isTeacher) {
        this.$eventBus.$emit('actions', {
          type: 'googleEvent',
          event,
          input: {
            mode: 'P',
            loadData: true,
            data: rawDayObject
          }
        });
      }
    } else if (target.matches('.section-edit-link-lesson') || target.matches('.section-edit-link-lesson .fa-link')) {
      if (this.$currentUser.isTeacher) {
        this.editLink = true;
        this.$eventBus.$emit('openSubPage', {
          type: 'lesson',
          modal: true,
          input: {
            loadData: true,
            data: rawDayObject,
            lessonListsView: this.isPlansListView
          }
        });
      }
    } else if (target.matches('.section-show-actions') || target.matches('.section-show-actions .fa-ellipsis-v')) {
      if (CommonUtils.hasNoText(rawDayObject.googleId)) {
        const excludes = LessonServices.excludedLessonActions(rawDayObject, this.userMode, this.localPage);
        if (this.$currentUser.isAdmin && rawDayObject.type === 'E') {
          this.getEventNotificationsById({ eventId: rawDayObject.eventId, customEventId: rawDayObject.customEventId }).then((data) => {
            rawDayObject.notifications = data.notifications;
          }).catch(() => {
            rawDayObject.notifications = [];
          }).finally(() => {
            this.$eventBus.$emit('actions', {
              type: rawDayObject.type === 'E' ? 'event' : 'lesson',
              event,
              input: {
                mode: 'P',
                loadData: true,
                data: rawDayObject,
                exclude: rawDayObject.collaborateSubjectId > 0 ? excludes : [],
                lessonListsView: rawDayObject.type === 'E' ? undefined : this.isPlansListView
              }
            });
          });
        } else {
          this.$eventBus.$emit('actions', {
            type: rawDayObject.type === 'E' ? 'event' : 'lesson',
            event,
            input: {
              mode: 'P',
              loadData: true,
              data: rawDayObject,
              exclude: rawDayObject.collaborateSubjectId > 0 ? excludes : [],
              lessonListsView: rawDayObject.type === 'E' ? undefined : this.isPlansListView
            }
          });
        }
      }
    } else if (target.matches('a')) {
      this.openLink(target, dayObject.rawDayObject);
    } else if (target.matches('.section-title[data-type="unit"]') ||
               target.closest('.section-title[data-type="unit"]') ||
               target.matches('.section-item-title[data-type="unit"]') ||
               target.closest('.section-item-title[data-type="unit"]')) {
      const unit = { ...rawDayObject.unit, isReadOnly: !this.$currentUser.isTeacher };
      this.$eventBus.$emit('openSubPage', {
        type: 'unit',
        modal: true,
        input: unit
      });
    } else if (target.matches('.section-title[data-type="linkedUnit"]') ||
               target.closest('.section-title[data-type="linkedUnit"]') ||
               target.matches('.section-item-title[data-type="linkedUnit"]') ||
               target.closest('.section-item-title[data-type="linkedUnit"]')) {
      const linkedUnit = { ...rawDayObject.linkedUnit, isReadOnly: !this.$currentUser.isTeacher };
      this.$eventBus.$emit('openSubPage', {
        type: 'unit',
        modal: true,
        input: linkedUnit
      });
    } else if (target.matches('.section-action') && CommonUtils.hasText(target.getAttribute('url'))) {
      window.open(target.getAttribute('url'), '_blank');
    } else if (dayObject.rawDayObject.type === 'GC') {
      window.open(dayObject.rawDayObject.courseUrl, '_blank');
    } else if (rawDayObject.type === 'E') {
      if (CommonUtils.hasNoText(rawDayObject.googleId) && ((rawDayObject.schoolId === 0 && rawDayObject.districtId === 0 && this.$currentUser.isTeacher) || (rawDayObject.schoolId > 0 && this.$currentUser.isAdmin) || (rawDayObject.districtId > 0 && this.$currentUser.isDistrictAdmin))) {
        if (this.$currentUser.isAdmin) {
          CommonUtils.showLoading();
          this.getEventNotificationsById({ eventId: rawDayObject.eventId, customEventId: rawDayObject.customEventId }).then((data) => {
            rawDayObject.notifications = data.notifications;
          }).catch(() => {
            rawDayObject.notifications = [];
          }).finally(() => {
            CommonUtils.hideLoading();
            this.$eventBus.$emit('openSubPage', {
              type: 'event',
              modal: true,
              input: {
                loadData: true,
                data: rawDayObject
              }
            });
          })
        } else {
          this.$eventBus.$emit('openSubPage', {
            type: 'event',
            modal: true,
            input: {
              loadData: true,
              data: rawDayObject
            }
          });
        }
      }
    } else {
      if (this.$currentUser.isTeacher) {
        this.editLink = false;
        let isModal = true;
        if (this.isPlansListView) {
          isModal = this.$currentUser.defaultEditorMode === 'modal';
        }
        this.$eventBus.$emit('openSubPage', {
          type: 'lesson',
          width: isModal ? undefined : 700,
          modal: isModal,
          input: {
            loadData: true,
            data: rawDayObject,
            lessonListsView: this.isPlansListView
          }
        });
      }
    }
  }

  checkAdminEventShift() {
    if (CommonUtils.isNotEmpty(this.adminEvents) && this.$currentUser.isTeacher) {
      return Promise.resolve().then(() => {
        if (this.isCycleSchedule) {
          CommonUtils.showLoading();
          return this.adminEventShift({ shiftLessons: true });
        } else {
          return this.$refs.confirm.confirm({
            title: this.$t('noSchoolEventLabel'),
            text: this.$t('adminShiftEventMsg'),
            option1ButtonAlternativeText: this.$t('shiftLessonsLabel'),
            option2ButtonAlternativeText: this.$t('doNotShiftLessonsLabel')
          }).then(result => {
            if (result === 1) {
              CommonUtils.showLoading();
              return this.adminEventShift({ shiftLessons: true });
            } else if (result === 2) {
              CommonUtils.showLoading();
              return this.adminEventShift({ shiftLessons: false });
            } else {
              return Promise.resolve();
            }
          });
        }
      });
    }
  }

  async doBeforeFetchPlans() {
    this.manageSchools = this.hasSchoolInvites;
    return Promise.resolve(true);
  }

  mounted() {
    this.doBeforeFetchPlans().then(fetch => {
      if (fetch) {
        return this.fetchPlans();
      }
    });
  }

  viewUnitLessons(unit: any) {
    if (!this.isPlansListView) {
      this.$store.commit('plans/setViewType', PlanbookStorage.get('unitViewLessonsParams').viewType);
    }
  }

  created() {
    this.$nextTick(() => {
      this.$eventBus.$emit('plansToolbarShow');
      this.$eventBus.$on('onSectionCardClicked', this.onSectionCardClicked);
      this.$eventBus.$on('onSectionCardContextMenu', this.onSectionCardContextMenu);
      this.$eventBus.$on('schoolYearChanged', this.changePlans);
      this.$eventBus.$on('checkAdminEventShift', this.checkAdminEventShift);
      this.$eventBus.$on('selectUnit', this.viewUnitLessons);
    })
  }

  beforeDestroy() {
    this.$eventBus.$emit('plansToolbarHide');
    this.$eventBus.$off('schoolYearChanged', this.changePlans);
    this.$eventBus.$off('onSectionCardClicked');
    this.$eventBus.$off('onSectionCardContextMenu');
    this.$eventBus.$off('checkAdminEventShift');
    this.$eventBus.$off('selectUnit', this.viewUnitLessons);
  }
}
