var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "plan-box-month",
      style: { height: _vm.planBoxHeight + "px" },
    },
    [
      _c(
        "div",
        {
          ref: "planBoxMonthHeader",
          staticClass: "plan-box-month-header",
          class: {
            "d-flex justify-space-between": _vm.$vuetify.breakpoint.smAndDown,
          },
        },
        [
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "pb-btn",
                {
                  attrs: { icon: "", label: _vm.$t("previousLabel") },
                  on: { click: _vm.prevLessonsEvents },
                },
                [_c("v-icon", [_vm._v("fal fa-angle-left")])],
                1
              )
            : _vm._e(),
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-menu",
                {
                  attrs: {
                    transition: "scale-transition",
                    "min-width": "auto",
                    "close-on-content-click": false,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "goto-date",
                                    staticStyle: {
                                      "font-size": "16px",
                                      "font-weight": "bold",
                                    },
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.gotoDateMenu = true
                                      },
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(_vm._s(_vm.displayMonth) + " "),
                                  _c("span", [_vm._v(_vm._s(_vm.displayYear))]),
                                ]),
                                _c("v-icon", [_vm._v("fal fa-angle-down")]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3485555445
                  ),
                  model: {
                    value: _vm.gotoDateMenu,
                    callback: function ($$v) {
                      _vm.gotoDateMenu = $$v
                    },
                    expression: "gotoDateMenu",
                  },
                },
                [
                  _c("v-date-picker", {
                    ref: "datePicker",
                    attrs: {
                      value: _vm.pickerGotoDate,
                      locale: _vm.getGlobalLanguage,
                    },
                    on: { change: _vm.onPickerFirstDayChanged },
                  }),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "text-center font-weight-bold",
                  staticStyle: { padding: "8px" },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.displayMonth) + " "),
                    _c("span", [_vm._v(_vm._s(_vm.displayYear))]),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "10px",
                        top: "8px",
                      },
                    },
                    [
                      _c(
                        "pb-btn",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            color:
                              _vm.monthViewType === "split"
                                ? "primary"
                                : "info",
                            label: _vm.$t("splitViewLabel"),
                            small: "",
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.monthViewType = "split"
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fal fa-table-columns")])],
                        1
                      ),
                      _c(
                        "pb-btn",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            color:
                              _vm.monthViewType === "full" ? "primary" : "info",
                            label: _vm.$t("detailedViewLabel"),
                            small: "",
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.monthViewType = "full"
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fal fa-table-cells")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "pb-btn",
                {
                  attrs: { icon: "", label: _vm.$t("nextLabel") },
                  on: { click: _vm.nextLessonsEvents },
                },
                [_c("v-icon", [_vm._v("fal fa-angle-right")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          {
            staticClass: "plan-box-month-body",
            style: {
              height: _vm.calendarHeight + "px",
              width: _vm.calendarWidth,
              display: _vm.calendarDisplay,
            },
          },
          [
            _c("v-calendar", {
              key: _vm.calendarHeight + _vm.calendarWidth,
              ref: "monthCalendar",
              attrs: {
                id: "monthCalendar",
                now: _vm.monthCurrentDay,
                start: _vm.monthStart,
                end: _vm.monthEnd,
                weekdays: _vm.weekdays,
                events: _vm.localCalendarMonthEvents,
                "event-more": false,
                locale: _vm.getGlobalLanguage,
              },
              on: {
                "click:date": _vm.doShowCalendarDay,
                "click:day": _vm.doShowCalendarDay,
              },
              scopedSlots: _vm._u(
                [
                  _vm.showDayName
                    ? {
                        key: "day-label",
                        fn: function (item) {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pl-2",
                                    staticStyle: {
                                      "font-size": "12px",
                                      "margin-top": "1px",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.getDayName(item)))]
                                ),
                                _c("div", { staticClass: "pr-2" }, [
                                  _vm._v(_vm._s(_vm.getDisplayDate(item))),
                                ]),
                              ]
                            ),
                          ]
                        },
                      }
                    : null,
                  {
                    key: "day",
                    fn: function (item) {
                      return [
                        _c(
                          "perfect-scrollbar",
                          {
                            staticClass: "plan-box-month-day",
                            class: {
                              past: _vm.isPast(item),
                              "reduce-brightness":
                                _vm.isReduceBrightnessOfPastDays,
                            },
                            staticStyle: {
                              height: "calc(100% - 25px)",
                              "overflow-y": "auto",
                              "padding-top": "3px",
                            },
                            style: { zoom: _vm.zoom },
                          },
                          [
                            _vm.monthViewType === "full"
                              ? _vm._l(
                                  _vm.getDayObjects(item),
                                  function (dayObject, i) {
                                    return _c("section-card", {
                                      key: `calendar-m-day-object-${i}-${dayObject.dayObjectId}`,
                                      attrs: {
                                        dayObject: dayObject,
                                        showDetails: _vm.showDetails,
                                        enableDragDrop: "",
                                        container: ".plan-box-month-body",
                                      },
                                    })
                                  }
                                )
                              : _vm._l(
                                  _vm.getCalendarEvents(item),
                                  function (event, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: `calendar-e-day-object-${i}`,
                                        staticClass:
                                          "v-event v-event-start v-event-end",
                                        staticStyle: {
                                          height: "20px",
                                          width: "95%",
                                          "margin-bottom": "1px",
                                        },
                                        style: {
                                          "background-color": event.color,
                                          "border-color": event.color,
                                          color: event.textColor,
                                          "font-weight": event.fontWeight,
                                          "font-style": event.fontStyle,
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "pl-1" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "v-event-summary",
                                              class:
                                                _vm.highContrastMode &&
                                                (_vm.hasText(event.time) ||
                                                  _vm.hasText(event.title))
                                                  ? "contrast-view"
                                                  : "",
                                              style: {
                                                "text-decoration":
                                                  event.textDecoration,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    height: "18px",
                                                  },
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(_vm._s(event.time)),
                                                  ]),
                                                  _vm._v(
                                                    " " + _vm._s(event.title)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showCalendarDay,
                expression: "showCalendarDay",
              },
            ],
            staticClass: "plan-box-month-detail",
            style: { width: _vm.dayWidth },
          },
          [
            _c(
              "v-card",
              { attrs: { elevation: "0" } },
              [
                _c(
                  "v-card-title",
                  { staticClass: "px-0 pt-0 pb-0" },
                  [
                    _c(
                      "v-toolbar",
                      { attrs: { elevation: "0" } },
                      [
                        _c(
                          "pb-btn",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              icon: "",
                              right: "",
                              label: _vm.$t("closeLabel"),
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                _vm.showCalendarDay = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("fal fa-times")])],
                          1
                        ),
                        _c("v-spacer"),
                        _c(
                          "div",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "pb-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          disabled: !_vm.hasSelectedCalendarDay,
                                          label: _vm.$t("previousLabel"),
                                        },
                                        on: { click: _vm.prevDay },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fal fa-angle-left"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-center",
                                    attrs: { cols: "auto" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "line-height": "45px",
                                          "font-size": "20px",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.stringifyDate()))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "text-right" },
                                  [
                                    _c(
                                      "pb-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          disabled: !_vm.hasSelectedCalendarDay,
                                          label: _vm.$t("nextLabel"),
                                        },
                                        on: { click: _vm.nextDay },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fal fa-angle-right"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "perfect-scrollbar",
              {
                staticClass: "plan-box-month-detail__body",
                staticStyle: { overflow: "auto" },
                style: {
                  height: (_vm.calendarHeight - 87) / _vm.zoom + "px",
                  zoom: _vm.zoom,
                },
              },
              [
                _c(
                  "div",
                  _vm._l(_vm.calendarDayObjects, function (dayObject, i) {
                    return _c("section-card", {
                      key: `calendar-day-object-${i}`,
                      attrs: {
                        dayObject: dayObject,
                        showDetails: _vm.showDetails,
                      },
                    })
                  }),
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }