var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVertical
    ? _c(
        "div",
        { staticClass: "plan-box-vertical plan-box fill-height" },
        [
          _c(
            "div",
            {
              ref: "planHeaderVertical",
              staticClass: "plan-header-vertical",
              attrs: { id: "planHeaderVertical" }
            },
            [
              _c(
                "div",
                _vm._l(_vm.plans, function(view, v) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: view.show,
                          expression: "view.show"
                        }
                      ],
                      key: "day-header-" + v,
                      staticClass: "day-header-item",
                      class: {
                        "text-right": _vm.viewType == "D",
                        leaving: _vm.leaving,
                        isCurrent: _vm.isCurrent(view),
                        "mobile-day-header-item":
                          _vm.$vuetify.breakpoint.smAndDown
                      },
                      staticStyle: { width: "auto" },
                      style: _vm.getStyle(view)
                    },
                    [_c("day-header", { attrs: { day: view } })],
                    1
                  )
                }),
                0
              ),
              _vm.showCount
                ? _c(
                    "div",
                    _vm._l(_vm.plans, function(view, v) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: view.show,
                              expression: "view.show"
                            }
                          ],
                          key: "day-header-count-" + v,
                          staticClass: "day-header-count-item",
                          staticStyle: { width: "auto" }
                        },
                        [
                          _c("day-count", {
                            attrs: { day: view, dayString: v }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]
          ),
          _c(
            "perfect-scrollbar",
            {
              staticClass: "plan-box-vertical-container",
              style: { height: _vm.planBoxHeight, "overflow-y": "scroll" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "plan-body-vertical plan-body",
                  class: { "adjust-to-fit": _vm.isAdjustToFit },
                  style: { zoom: _vm.zoom, width: "100%" }
                },
                [
                  _vm.isEqual
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "plan-body-vertical__events-top-banner",
                            staticStyle: { "padding-top": "10px" }
                          },
                          _vm._l(_vm.ezPlansEvents, function(view, v) {
                            return _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: view.show,
                                    expression: "view.show"
                                  }
                                ],
                                key: "day-" + v,
                                class: {
                                  past: view.past,
                                  "reduce-brightness":
                                    _vm.isReduceBrightnessOfPastDays
                                },
                                style: { width: _vm.width }
                              },
                              _vm._l(view.dayObjects, function(dayObject, i) {
                                return _c("section-card", {
                                  key:
                                    "day-object-v-ez-event-" +
                                    (v + "-" + i) +
                                    "-" +
                                    dayObject.dayObjectId,
                                  attrs: {
                                    dayObject: dayObject,
                                    showDetails: _vm.showDetails,
                                    enableDragDrop: "",
                                    container: ".plan-body-vertical.plan-body"
                                  }
                                })
                              }),
                              1
                            )
                          }),
                          0
                        ),
                        _vm._l(_vm.maxElements, function(n) {
                          return _c(
                            "div",
                            {
                              key: "day-row-" + n,
                              staticClass: "plan-body-vertical__ez-row"
                            },
                            _vm._l(_vm.ezPlansLessons, function(view, v) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: view.show,
                                      expression: "view.show"
                                    }
                                  ],
                                  key: "day-" + v,
                                  staticClass: "plan-body-vertical__ez-column",
                                  class: {
                                    past: view.past,
                                    "reduce-brightness":
                                      _vm.isReduceBrightnessOfPastDays
                                  },
                                  style: { width: _vm.width }
                                },
                                [
                                  !["dayCount", "maxElements"].includes(v) &&
                                  _vm.isArrayNotEmpty(view.dayObjects) &&
                                  n <= view.dayObjects.length
                                    ? _c(
                                        "div",
                                        { style: _vm.ezElementStyle },
                                        [
                                          _vm.isArrayNotEmpty(
                                            view.dayObjects
                                          ) && n <= view.dayObjects.length
                                            ? _c("section-card", {
                                                key:
                                                  "day-object-v-ez-lesson-" +
                                                  n +
                                                  "-" +
                                                  v +
                                                  "-" +
                                                  view.dayObjects[n - 1]
                                                    .dayObjectId,
                                                attrs: {
                                                  dayObject:
                                                    view.dayObjects[n - 1],
                                                  showDetails: _vm.showDetails,
                                                  enableDragDrop: "",
                                                  container:
                                                    ".plan-body-vertical.plan-body"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          )
                        })
                      ]
                    : _c(
                        "div",
                        _vm._l(_vm.plans, function(view, v) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: view.show,
                                  expression: "view.show"
                                }
                              ],
                              key: "day-" + v,
                              class: {
                                past: view.past,
                                "reduce-brightness":
                                  _vm.isReduceBrightnessOfPastDays
                              },
                              style: { width: _vm.width }
                            },
                            _vm._l(view.dayObjects, function(dayObject, i) {
                              return _c("section-card", {
                                key:
                                  "day-object-v-" +
                                  (v + "-" + i) +
                                  "-" +
                                  dayObject.dayObjectId,
                                attrs: {
                                  dayObject: dayObject,
                                  showDetails: _vm.showDetails,
                                  enableDragDrop: "",
                                  container: ".plan-body-vertical.plan-body"
                                }
                              })
                            }),
                            1
                          )
                        }),
                        0
                      )
                ],
                2
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }