var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-modal",
    {
      attrs: { withActions: false, expandable: false, maxWidth: 650 },
      on: { apply: _vm.doApply },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("manageSchoolsLabel")))]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "editor" },
          [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", {
                              staticClass: "pb-0",
                              attrs: { cols: "12" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("manageUserSchoolsMsg")
                                )
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "pt-0", attrs: { cols: "12" } },
                              [
                                _c("v-data-table", {
                                  attrs: {
                                    headers: _vm.headers,
                                    items: _vm.userSchoolsToManage,
                                    "fixed-header": "",
                                    "hide-default-footer": "",
                                    "disable-pagination": "",
                                    "item-key": "schoolId"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item.primary",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.setToPrimary(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: _vm.isTrue(
                                                            item.primary
                                                          )
                                                            ? "#FFA500"
                                                            : undefined
                                                        }
                                                      },
                                                      [
                                                        _vm.isTrue(item.primary)
                                                          ? [
                                                              _vm._v(
                                                                "fas fa-star"
                                                              )
                                                            ]
                                                          : [
                                                              _vm._v(
                                                                "fal fa-star"
                                                              )
                                                            ]
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "item.action",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c("td", [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          $event.preventDefault()
                                                          return _vm.removeSchool(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fal fa-unlink"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }