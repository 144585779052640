import moment from 'moment-timezone';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const plans = namespace('plans');
const settings = namespace('settings');

@Component
export default class PlansViewMixin extends Vue {
    @plans.State
    month!: string;

    @settings.Getter('getSettings')
    settings!: any;

    @plans.State
    viewType!: string;

    @plans.State
    expandNotesTodo!: boolean;

    @plans.Getter('getCurrentPlans')
    currentPlans!: any;

    @plans.Getter('getCurrentCyclePlans')
    currentCyclePlans!: any;

    @plans.Action
    gotoLessonsEvents!: (param: Date) => Promise<any>;

    @settings.Getter('getLessonOrientationSettings')
    orientationSettings!: any;

    @settings.State
    userMode!: string;

    @settings.State
    showDetails!: boolean;

    @settings.Getter('getBrowserHeight')
    browserHeight!: number;

    @settings.Getter('getBrowserWidth')
    browserWidth!: number;

    @plans.Getter('getEnabledDaysCount')
    enabledDaysCount!: any;

    @plans.Getter('getEnabledDayNums')
    weekdays!: any;

    @settings.Getter
    isReduceBrightnessOfPastDays!: boolean;

    @plans.Getter('isVertical')
    isVertical!: boolean;

    @plans.Getter('isHorizontal')
    isHorizontal!: boolean;

    @plans.Getter('isEqual')
    isEqual!: boolean;

    @plans.Getter('isAdjustToFit')
    isAdjustToFit!: boolean;

    @plans.Getter('getBoxSize')
    boxSize!: number;

    @plans.Getter('getZoom')
    zoom!: number;

    @plans.Getter('getMaxElements')
    maxElements!: number;

    @plans.Action
    nextLessonsEvents!: (param?: any) => Promise<any>;

    @plans.Action
    prevLessonsEvents!: (param?: any) => Promise<any>;

    @plans.State
    viewKey!: string;

    @settings.Getter('getDateStyling')
    dateStyling!: any;

    isMounted = false;

    get bodyHeight() {
      return this.browserHeight;
    }

    get isArrayNotEmpty() {
      return CommonUtils.isNotEmpty;
    }

    get currentDateBackgroundColor() {
      return this.dateStyling.currentDateBackgroundColor;
    }

    get currentDateTextColor(): string {
      return this.dateStyling.currentDateTextColor;
    }

    mounted() {
      this.$nextTick(() => {
        this.isMounted = true;
      });
    }

    isCurrent(view: any) {
      const date = moment(view.date, 'MM/DD/YYYY');
      const curr = moment();
      return curr.diff(date, 'days') === 0 && curr.isSame(date, 'day');
    }

    getAppBarBottomOffset() {
      const element = document.querySelector('.app-bar .v-toolbar__content') as HTMLElement;
      const rect = element.getBoundingClientRect();
      return rect.top + element.offsetHeight;
    }

    getStyle(view: any) {
      if (this.isCurrent(view)) {
        return {
          backgroundColor: this.currentDateBackgroundColor,
          color: this.currentDateTextColor
        }
      }
    }
}
