
import { Component, Vue } from 'vue-property-decorator';
import VerticalView from '@/components/plans/views/VerticalView.vue';
import HorizontalView from '@/components/plans/views/HorizontalView.vue';
import { namespace } from 'vuex-class';

const plans = namespace('plans');

@Component({
  components: {
    VerticalView,
    HorizontalView
  }
})
export default class WeekDayView extends Vue {
  @plans.Getter('isHorizontal')
  isHorizontal!: boolean;
}
