






























































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PlansViewMixin from '@/mixins/plans-view-mixin';
import { namespace } from 'vuex-class';
import draggable from 'vuedraggable';
import ld from 'lodash';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import LessonServices from '@/services/lesson-services';
import LessonActions from '@/components/lessons/LessonActions.vue';
import { PlanbookStorage } from '@/common/planbook-storage';
import { infiniteScrollDelay } from '@/constants';
const tinycolor = require('tinycolor2');
const units = namespace('units');
const plans = namespace('plans');
const classes = namespace('classes');
const lessons = namespace('lessons');
const settings = namespace('settings');
const lessonlists = namespace('lessonlists');

@Component({
  mixins: [PlansViewMixin],
  components: {
    draggable,
    LessonActions
  }
})
export default class ListView extends Vue {
  $refs!: {
    confirm: any,
    sentinel: any,
    topSentinel: any,
    scrollContainer:any,
    listRow:any
  }

  loading = false;
  showUnitEditor = false;
  bodyHeight!: number;
  browserWidth!: number;
  localClassLessons: any[] = [];
  search = '';
  lessonListItems: any[] = [];
  selectedUnits = this.$t('allUnitsLabel');
  isReduceBrightnessOfPastDays!: boolean;
  isExpanded = false;
  selectedItem:any = false;
  localEditingLesson = '';
  localSlideGroupModel = null;

  infiniteScrollList:any[] = [];
  /**
   * if we extracted infinite-scroll to a generic component,
   * it would need just these props.loadingMore,canLoadMore and the list
   */

  isLoadingMore = false;
  canLoadMore = true; // list has to end at some point

  // extra stuff
  initialLoad = true; // initial load state ,if you want  to use a different BIGGER loading animation  etc
  pageNumber = 0; // canLoadMore is set to false when last page is reached
  pageCount = 0;
  listEndObserver:any = undefined;

  isLoadingMoreTop = false;
  canLoadMoreTop = true; // list has to end at some point

  previousScrollHeightMinusScrollTop:any = undefined;
  listEndObserverTop:any = undefined;

  currentDatePosition = 0;
  localIsEditMode = false;
  isAddingExtraLesson = false;
  addedExtraLessonToOpen = null;
  autoSave = false;

  localHeaders = [
    {
      sortable: false, value: 'lessonNum', width: 10
    },
    {
      sortable: false, value: 'formattedDate', width: 150
    },
    {
      sortable: false, value: 'units', width: 150
    },
    {
      sortable: false, value: 'lessonTitle'
    },
    {
      sortable: false, value: 'action', width: 100
    }
  ]

  @settings.Getter
  getSharingOptionsSettings!: any;

  @settings.Getter('getUserType')
  userType!: string;

  @settings.Getter('getStatuses')
  allLessonStatus!: Array<any>;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter('getDateStyling')
  dateStyling!: any;

  @settings.Getter('getStudentTeacherId')
  studentTeacherId!: any;

  @lessonlists.Getter('getSelectedLessonsInLessonList')
  selectedLessonListLessons!: any[];

  @classes.Getter('getClassItems')
  classItems!: any[];

  @classes.State
  classUnits!: any;

  @classes.Getter('getLessonBankItems')
  lessonBankItems!: Array<any>;

  @classes.Action
  loadClasses!: (params?: any) => Promise<any>;

  @classes.Getter('getDistrictLessonBankItems')
  districtLessonBankItems!: Array<any>;

  @classes.Mutation
  setHaveClass!: (value: boolean) => void;

  @plans.Getter('getCurrentClassLessons')
  classLessons!: any[];

  @plans.State
  viewType!: string;

  @classes.Getter('getClassUnits')
  getClassUnits!: (classId: number) => any[];

  @plans.Action
  loadClassLessons!: (params?: any) => Promise<any>;

  @units.Action('save')
  saveUnit!: (params?: any) => Promise<any>;

  @units.Action('deleteUnitsFromLessonlist')
  deleteUnits!: (params?: any) => Promise<any>;

  @lessons.Action('save')
  saveLesson!: (params?: any) => Promise<any>;

  @lessons.Action
  reorder!: (params?: any) => Promise<any>;

  @units.Action('init')
  initUnit!: (params?: any) => Promise<any>;

  @lessons.Action('init')
  initLesson!: (params?: any) => Promise<any>;

  @lessons.Getter
  createNewLesson!: () => any;

  @units.Mutation
  addUpdatedField!: (value: string) => void;

  @lessons.Mutation
  setUnitId!: (value: number) => void;

  @settings.Getter('getLessonRetrieval')
  lessonRetrieval!: any;

  @settings.Getter
  highContrastMode!: boolean;

  @settings.Action
  reloadSettings!: (params?: any) => Promise<any>;

  @units.Action
  loadUnits!: (params?: any) => Promise<any>;

  get hasText() {
    return CommonUtils.hasText;
  }

  get isEmpty() {
    return CommonUtils.isEmpty;
  }

  get lessonUniqueIdentifier() {
    return LessonServices.getLessonUniqueIdentifier;
  }

  get isThereSelectedClass() {
    if (this.currentSubjectId !== 0) {
      return true;
    } else {
      return false;
    }
  }

  get lessonStatus() {
    const lessonStatus:any[] = [-1];
    this.allLessonStatus.forEach((element:any) => {
      lessonStatus.push(element.lessonStatusId);
    });
    return lessonStatus;
  }

  get hasSharedClasses() {
    return CommonUtils.isNotEmpty(this.sharedClasses);
  }

  get sharedClasses():any[] {
    const sharedClasses:any[] = [];
    this.getSharingOptionsSettings.sharedList.forEach((shared:any) => {
      shared.years.forEach((year:any) => {
        if (year.yearId > 0) {
          year.classes.forEach((classes:any) => {
            classes.text = classes.className;
            sharedClasses.push(classes);
          });
        }
      });
    });
    return sharedClasses.sort((a:any, b:any) => {
      const fa = a.email.toLowerCase();
      const fb = b.email.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }

  get currentTeacherId(): number {
    if (this.userMode === 'T') {
      return +this.$store.state.settings.userId;
    } else {
      return +this.$store.state.settings.currentTeacherId;
    }
  }

  get currentYearId(): number {
    if (this.userMode === 'T') {
      return +this.$store.getters['settings/getCurrentSchoolYearId'];
    } else {
      return +this.$store.state.settings.currentYearId;
    }
  }

  get fetchWeekSize(): number {
    return this.getNumber('fetchWeekSize', +this.lessonRetrieval.fetchWeekSize);
  }

  getNumber(key: string, defaultValue: any) {
    if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
      return +this.updatedSettings[key];
    }
    return +defaultValue;
  }

  get updatedSettings(): any {
    return this.$store.state.settings.updatedSettings;
  }

  get unit() {
    return this.$store.state.units.unit || {};
  }

  set unit(value: any) {
    this.$store.commit('units/setUnit', value);
  }

  get currentSubjectId() {
    return +this.$store.state.settings.currentSubjectId;
  }

  set currentSubjectId(value: number) {
    this.$store.commit('settings/setCurrentSubjectId', +value);
  }

  get unitNum(): string {
    return this.unit.unitNum || '';
  }

  set unitNum(value: string) {
    this.setValue('unitNum', value);
  }

  get unitTitle(): string {
    return this.unit.unitTitle || '';
  }

  set unitTitle(value: string) {
    this.setValue('unitTitle', value);
  }

  get subjectId(): number {
    return +this.unit.subjectId || 0;
  }

  set subjectId(value: number) {
    this.setValue('subjectId', value);
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  getButtonBGColor(lesson: any) {
    if (lesson.data.unitId > 0) {
      return this.currentColor;
    }
    return '#b5bac1';
  }

  getButtonTextColor(lesson: any) {
    if (lesson.data.unitId > 0) {
      return this.currentTextColor;
    }
    return '#FFFFFF';
  }

  get currentColor() {
    const item = this.localClassItems.filter((c: any) => +c.value === this.currentSubjectId)[0];
    return item ? item.color : undefined;
  }

  get units():any[] {
    return this.getClassUnits(this.currentSubjectId).sort((u1, u2) => {
      return u1.num.localeCompare(u2.num);
    });
  }

  get currentTextColor() {
    if (this.currentColor) {
      const c = tinycolor(this.currentColor);
      if (c.getBrightness() < 220) {
        return '#FFFFFF';
      } else {
        return '#000000';
      }
    } else {
      return '#000000';
    }
  }

  get localClassItems() {
    return this.classItems.filter((c: any) => !c.isGoogleClass && (c.teacherId === this.studentTeacherId || this.studentTeacherId === 0));
  }

  get localClassName() {
    return (this.classItems.filter((c: any) => c.value === this.currentSubjectId)[0] ||
    this.sharedClasses.filter((c: any) => c.classId === this.currentSubjectId)[0] ||
    { text: this.$t('selectAClassLabel') }).text;
  }

  get currentSelectedClassColor() {
    const color = (this.classItems.filter((c: any) => c.value === this.currentSubjectId)[0] ||
    this.sharedClasses.filter((c: any) => c.classId === this.currentSubjectId)[0] ||
    { color: this.$vuetify.theme.currentTheme.primary }).color;
    if (this.isColorReadable(color)) {
      return color;
    } else {
      return this.$vuetify.theme.currentTheme.fg;
    }
  }

  get height() {
    return (this.bodyHeight + 7) + 'px';
  }

  get currentDate() {
    const currentDate = new Date();
    const currentDay = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
    const nCurrentMonth = currentDate.getMonth() + 1;
    const currentMonth = (nCurrentMonth < 10 ? '0' : '') + nCurrentMonth;
    const currentDatemmddyyyy = currentMonth + '/' + currentDay + '/' + currentDate.getFullYear();
    return currentDatemmddyyyy;
  }

  get currentDateBackgroundColor() {
    return CommonUtils.getColor(this.getValue('currentDateBackgroundColor', this.dateStyling.currentDateBackgroundColor || 'inherit'));
  }

  get currentDateTextColor() {
    return CommonUtils.getColor(this.getValue('currentDateTextColor', this.dateStyling.currentDateTextColor || 'inherit'));
  }

  get darkMode(): boolean {
    return this.$vuetify.theme.dark;
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  editLesson(lesson: any) {
    console.log(JSON.stringify(lesson));
  }

  setValue(key: string, value: any): void {
    this.unit = ld.set(ld.cloneDeep(this.unit), key, value);
    this.addUpdatedField(key.toUpperCase());
  }

  @Watch('classLessons')
  onClassLessonsChanged() {
    if (PlanbookStorage.get('unitViewLessonsParams')) return;
    this.lessonListItems = this.selectedLessonListLessons;
    this.localClassLessons = this.classLessons;
  }

  onDragEnd() {
    this.loading = true;
    const lessonIds: number[] = [];
    this.localClassLessons.forEach((l: any) => {
      const lesson = l.data;
      if (lesson.lessonLock !== 'Y') {
        lessonIds.push(+lesson.lessonId || 0);
      }
    });
    this.reorder({ subjectId: this.currentSubjectId, lessonIds }).finally(() => {
      this.loading = false;
    });
  }

  async selectUnit(lesson: any, unit: any) {
    const that = this;
    CommonUtils.showLoading();
    return this.initLesson({ data: ld.cloneDeep(lesson), loadData: true }).then(async () => {
      this.setUnitId(unit.id);
      return this.saveLesson();
    }).finally(() => {
      lesson.unitNum = unit.num || unit.unitNum;
      lesson.unitId = unit.id;
      lesson.unit = ld.cloneDeep(unit);
      if (that.selectedUnits !== that.$t('allUnitsLabel')) {
        that.filterUnits(that.units.find(u => u.num === that.selectedUnits));
      }
      CommonUtils.hideLoading();
    });
  }

  async fetchClassLessons() {
    this.loading = true;
    return this.loadClassLessons().finally(async () => {
      this.loading = false;
    });
  }

  doInit(openDate?: string) {
    Promise.resolve().then(() => {
      if (this.currentSubjectId > 0) {
        if (CommonUtils.isEmpty(this.classLessons)) {
          return this.fetchClassLessons();
        } else {
          this.localClassLessons = this.classLessons;
        }
      }
      return Promise.resolve();
    }).then(async () => {
      if (this.userMode !== 'S') {
        return this.loadUnits().then(() => {
          this.initialize(openDate);
        });
      } else {
        this.initialize();
        return Promise.resolve();
      }
    });
  }

  created() {
    CommonUtils.showLoading();
    this.setHaveClass(false);
    this.loadClasses();
    const that = this;
    Promise.resolve().then(() => {
      if (that.currentSubjectId > 0) {
        if (CommonUtils.isEmpty(that.classLessons)) {
          return that.fetchClassLessons();
        } else {
          that.localClassLessons = that.classLessons;
        }
      }
      return Promise.resolve();
    }).then(async () => {
      CommonUtils.showLoading();
      if (that.userMode !== 'S') {
        return that.loadUnits().then(() => {
          that.initialize();
        });
      } else {
        that.initialize();
        return Promise.resolve();
      }
    }).finally(() => {
      if (PlanbookStorage.get('unitViewLessonsParams')) return;
      CommonUtils.hideLoading();
    });
  }

  onSchoolYearChanged() {
    this.currentSubjectId = 0;
    this.resetInfiniteScrollData();
    this.doInit();
  }

  onUserModeChanged() {
    this.currentSubjectId = 0;
    this.resetInfiniteScrollData();
    this.doInit();
  }

  destroyed() {
    this.$eventBus.$off('lessonHasBeenSave');
    this.$eventBus.$off('lessonEditorClosed');
    this.$eventBus.$off('schoolYearChanged', this.onSchoolYearChanged);
    this.$eventBus.$off('userModeChanged', this.onUserModeChanged);
    this.$eventBus.$off('selectUnit', this.filterUnits);
    this.$eventBus.$off('previousLesson');
    this.$eventBus.$off('nextLesson');
    if (this.listEndObserver) {
      this.listEndObserver.disconnect();
    }
    if (this.listEndObserverTop) {
      this.listEndObserverTop.disconnect();
    }
    this.$eventBus.$emit('closeSubPage');
    // if (this.$route.query) {
    //   const nullValue:any = null;
    //   this.$nextTick(() => {
    //     this.$router.replace({ query: nullValue });
    //   });
    // }
  }

  mounted() {
    const that = this;
    this.$nextTick(() => {
      this.$eventBus.$on('schoolYearChanged', this.onSchoolYearChanged);
      this.$eventBus.$on('userModeChanged', this.onUserModeChanged);
      this.$eventBus.$on('selectUnit', this.filterUnits);

      this.$eventBus.$on('lessonHasBeenSave', (resp:any) => {
        let params:any;
        if (resp?.autoSave) {
          params = resp.data;
          that.autoSave = resp.autoSave;
        } else {
          params = resp;
          that.autoSave = false;
        }
        let filterUnits = false;
        that.infiniteScrollList.forEach((l:any, index:number) => {
          for (const lessonList of that.lessonListItems) {
            if (params && params.extraLesson > 0) {
              const classId = l.classId || l.subjectId;
              const lessonUniqueIdentifier = classId + '-' + l.date + '-' + params.extraLesson;
              if (lessonUniqueIdentifier === that.lessonUniqueIdentifier(lessonList)) {
                if (!that.infiniteScrollList.some((l:any) => lessonUniqueIdentifier === l.classId + '-' + l.date + '-' + l.extraLesson)) {
                  that.infiniteScrollList.splice(index + 1, 0, lessonList);
                  that.currentDatePosition = index + 1;
                  that.isAddingExtraLesson = true;
                  that.addedExtraLessonToOpen = ld.cloneDeep(lessonList);
                  setTimeout(() => {
                    if (!that.autoSave) {
                      that.openLessonViewMode(that.addedExtraLessonToOpen, false);
                    }
                  }, 500);
                }
              }
            } else {
              if (that.lessonUniqueIdentifier(l) === that.lessonUniqueIdentifier(lessonList)) {
                Vue.set(that.infiniteScrollList, index, lessonList);
                if (that.selectedUnits !== that.$t('allUnitsLabel') && lessonList.num !== that.selectedUnits) {
                  filterUnits = true;
                }
              }
            }
          }
        });
        // If Added Extra Lesson is NOT yet in the infiniteScrollList
        if (params && params.extraLesson > 0 && (!that.isAddingExtraLesson && !CommonUtils.hasValue(that.addedExtraLessonToOpen))) {
          const lessonUniqueIdentifier = params.subjectId + '-' + params.lessonDate + '-' + params.extraLesson;
          that.isAddingExtraLesson = true;
          that.addedExtraLessonToOpen = that.lessonListItems.find((lessonList:any, index:number) => {
            if (that.lessonUniqueIdentifier(lessonList) === lessonUniqueIdentifier) {
              that.currentDatePosition = index;
              return true;
            }
            return false;
          });
          this.doInit();
        } else {
          if (filterUnits) {
            that.filterUnits(that.units.find(u => u.num === that.selectedUnits));
          }
        }
        CommonUtils.hideLoading();
      });
      this.$eventBus.$on('lessonEditorClosed', () => {
        this.localEditingLesson = '';
        for (const row of this.$refs.listRow) {
          row.bgColor = '';
        }
      });
      this.$eventBus.$on('previousLesson', (payload:any) => {
        this.localIsEditMode = payload.isEditMode;
        this.upArrowClick(payload.item);
      });
      this.$eventBus.$on('nextLesson', (payload:any) => {
        this.localIsEditMode = payload.isEditMode;
        this.downArrowClick(payload.item);
      });
      this.$eventBus.$on('closeSubPage', () => {
        if (this.$refs.listRow) {
          for (const row of this.$refs.listRow) {
            row.bgColor = '';
          }
        }
      });
    });
  }

  firstHundredChar(html:string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    function getTextNodes(node: any): any {
      let allTextNodes: Array<any> = [];
      if (node.nodeType === Node.TEXT_NODE) {
        allTextNodes.push(node);
      } else {
        for (const childNode of node.childNodes) {
          allTextNodes = allTextNodes.concat(getTextNodes(childNode));
        }
      }
      return allTextNodes;
    }

    const textNodes = getTextNodes(doc.body);
    let textContent = '';

    for (const node of textNodes) {
      textContent += node.nodeValue + ' ';
    }

    return textContent.substring(0, 100);
  }

  hoverEnterEdit(item: any) {
    this.selectedItem = this.lessonUniqueIdentifier(item);
  }

  hoverExitEdit() {
    this.selectedItem = false;
  }

  openLessonViewMode(item:any, arrowClicked:boolean) {
    // this.$eventBus.$emit('closeSubPage');
    this.$nextTick(() => {
      this.localIsEditMode = false;
      this.$eventBus.$emit('openSubPage', {
        type: 'lessonViewMode',
        width: 600,
        modal: false,
        input: {
          rawDayObject: item,
          lessonListsView: true
        }
      });
      if (!arrowClicked) {
        for (const row of this.$refs.listRow) {
          if (row.id === this.lessonUniqueIdentifier(item)) {
            const elementToFocus:any = document.getElementById(row.id);
            elementToFocus.focus();
            row.bgColor = this.darkMode ? '#434343' : '#eeeeee';
          } else {
            row.bgColor = '';
          }
        }
      }
    });
  }

  openLessonEditMode(item:any, arrowClicked:boolean) {
    this.$nextTick(() => {
      if (this.$currentUser.isTeacher) {
        this.localEditingLesson = this.lessonUniqueIdentifier(item);
        this.localIsEditMode = true;
        const dayObject = this.getLocalDayObject(item, item.dayObject);
        if (dayObject.rawDayObject.collaborateSubjectId > 0 && dayObject.rawDayObject.collaborateType === 1) {
          return;
        }
        this.initLesson({ data: dayObject.rawDayObject })
        this.$eventBus.$emit('openSubPage', {
          type: 'lesson',
          width: 700,
          modal: this.$currentUser.defaultEditorMode === 'modal',
          input: {
            loadData: true,
            data: dayObject.rawDayObject,
            dayObject: dayObject,
            readonly: false,
            lessonListsView: true,
            itemData: item,
            skipDirtyCheck: arrowClicked
          }
        });
        if (!arrowClicked) {
          for (const row of this.$refs.listRow) {
            if (row.id === this.localEditingLesson) {
              const elementToFocus:any = document.getElementById(row.id);
              elementToFocus.focus();
              row.bgColor = this.darkMode ? '#434343' : '#eeeeee';
            } else {
              row.bgColor = '';
            }
          }
        }
      }
    });
  }

  downArrowClick(item:any) {
    const that = this;
    if (!this.isLoadingMore) {
      this.$refs.listRow.forEach((row:any, index:number) => {
        try {
          if (row.id === this.lessonUniqueIdentifier(item)) {
            row.bgColor = '';
            const elementToFocus:any = document.getElementById(this.$refs.listRow[index + 1].id);
            this.infiniteScrollList.forEach((listData:any, indexList:number) => {
              if (row.id === that.lessonUniqueIdentifier(listData)) {
                that.$refs.listRow[index + 1].bgColor = this.darkMode ? '#434343' : '#eeeeee';
                elementToFocus.focus();
                if (that.localIsEditMode) {
                  that.openLessonEditMode(that.infiniteScrollList[indexList + 1], true);
                } else {
                  that.openLessonViewMode(that.infiniteScrollList[indexList + 1], true);
                }
              }
            });
          }
        } catch (e) {
          console.log('Loading...');
        }
      });
    }
  }

  upArrowClick(item:any) {
    const that = this;
    if (!this.isLoadingMoreTop) {
      this.$refs.listRow.forEach((row:any, index:number) => {
        try {
          if (row.id === that.lessonUniqueIdentifier(item)) {
            row.bgColor = '';
            const elementToFocus:any = document.getElementById(this.$refs.listRow[index - 1].id);
            this.infiniteScrollList.forEach((listData:any, indexList:number) => {
              if (row.id === that.lessonUniqueIdentifier(listData)) {
                that.$refs.listRow[index - 1].bgColor = this.darkMode ? '#434343' : '#eeeeee';
                elementToFocus.focus();
                if (that.localIsEditMode) {
                  that.openLessonEditMode(that.infiniteScrollList[indexList - 1], true);
                } else {
                  that.openLessonViewMode(that.infiniteScrollList[indexList - 1], true);
                }
              }
            });
          }
        } catch (e) {
          console.log('Loading...');
        }
      });
    }
  }

  getLocalDayObject(data:any, dayObject:any) {
    const rawDayObject = ld.cloneDeep(data);
    delete rawDayObject.dayObject;
    const obj = {
      ...dayObject,
      rawDayObject: rawDayObject
    }
    return obj
  }

  addOrEditUnits(item:any) {
    const unitData = this.$store.state.units.units.filter((unit:any) => +unit.unitId === +item.id);
    this.$eventBus.$emit('openSubPage', {
      type: 'unit',
      width: 600,
      modal: true,
      input: unitData[0] || { subjectId: this.currentSubjectId }
    });
  }

  async deleteUnit(item:any, reloadSettings:boolean) {
    const unitData = this.$store.state.units.units.filter((unit:any) => +unit.unitId === +item.id);
    return this.$refs.confirm.confirm({
      title: this.$t('deleteUnitsLabel'),
      text: this.$t('confirmDeleteUnitsMg'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result:any) => {
      if (result === 1) {
        CommonUtils.showLoading();
        return this.deleteUnits({
          action: 'D',
          unitId: unitData[0].unitId,
          subjectId: unitData[0].subjectId,
          userMode: this.userMode
        }).then(() => {
          if (reloadSettings) {
            return this.reloadSettings();
          } else {
            return Promise.resolve();
          }
        })
          .then(() => {
            this.initialize(undefined, true);
          })
          .finally(() => {
            CommonUtils.hideLoading();
          });
      }
    });
  }

  importCSV() {
    this.$eventBus.$emit('openSubPage', {
      type: 'csv',
      width: 600,
      modal: true,
      input: {
        type: this.$t('lessonBanksLabel'),
        importWhat: 'lessons'
      }
    });
  }

  // showAllUnits() {
  //   this.selectedUnits = this.$t('allUnitsLabel');
  //   this.infiniteScrollList = this.selectedLessonListLessons;
  // }

  filterUnits(unit:any) {
    this.selectedUnits = unit.num || unit.unitNum;
    const listItems:any[] = [];
    this.selectedLessonListLessons.forEach((lesson:any) => {
      if (lesson.unitNum === unit.num || lesson.unitNum === unit.unitNum) {
        listItems.push(lesson);
      }
    });
    this.lessonListItems = listItems;
    if (CommonUtils.isNotEmpty(this.lessonListItems)) {
      this.initializeInfiniteScroll();
    } else {
      this.canLoadMore = false;
      this.canLoadMoreTop = false;
      this.infiniteScrollList = [];
    }
  }

  async changeClass(id: number, showLoading:boolean) {
    const that = this;
    let fromPermissions = false;
    let yearId = '';
    let teacherId = '';

    this.getSharingOptionsSettings.sharedList.forEach((shared:any) => {
      shared.years.forEach((year:any) => {
        year.classes.forEach((classes:any) => {
          if (classes.classId === id) {
            if (classes.fromPermissions) {
              fromPermissions = true;
              yearId = classes.yearId;
              teacherId = classes.teacherId;
            }
          }
        });
      });
    });
    if (showLoading) {
      CommonUtils.showLoading();
    }

    let payload:any = {
      lessonView: 'list',
      userMode: this.userMode,
      teacherId: teacherId,
      yearId: yearId,
      classId: id,
      fetchWeekSize: this.fetchWeekSize,
      allLessons: true,
      weeks: false,
      fetchDetails: true,
      lessonStatus: this.lessonStatus,
      filterStatus: this.lessonStatus.length > 0,
      fromPermissions: fromPermissions
    };
    if (!fromPermissions) {
      delete payload.fromPermissions;
    }
    if (this.userMode === 'A') {
      payload = undefined;
    }
    this.currentSubjectId = id;
    return this.loadClassLessons(payload).then(() => {
      that.lessonListItems = that.selectedLessonListLessons;
      that.selectedUnits = this.$t('allUnitsLabel');
      that.refresh();
      this.initializeInfiniteScroll();
      if (showLoading) {
        CommonUtils.hideLoading();
      }
    });
  }

  resetInfiniteScrollData() {
    this.infiniteScrollList = [];

    if (this.listEndObserver) {
      this.listEndObserver.disconnect();
    }
    if (this.listEndObserverTop) {
      this.listEndObserverTop.disconnect();
    }

    this.isLoadingMore = false;
    this.canLoadMore = true;

    this.initialLoad = true;
    this.pageNumber = 0;
    this.pageCount = 0;
    this.listEndObserver = undefined;

    this.isLoadingMoreTop = false;
    this.canLoadMoreTop = true;

    this.previousScrollHeightMinusScrollTop = undefined;
    this.listEndObserverTop = undefined;
    if (!this.isAddingExtraLesson) {
      this.currentDatePosition = 0;
    }
  }

  initializeInfiniteScroll (openDate?: string) {
    const that = this;
    that.resetInfiniteScrollData();

    if (this.currentSubjectId > 0) {
      let pageNumber;
      if (!this.isAddingExtraLesson) {
        let currentDatePosition = 0;
        this.lessonListItems.forEach((lessonList:any, index:number) => {
          if (lessonList.date === (openDate || this.currentDate)) {
            currentDatePosition = index;
          }
        });
        this.currentDatePosition = currentDatePosition;
        pageNumber = currentDatePosition / 10;
      } else {
        pageNumber = that.currentDatePosition / 10;
      }
      if (pageNumber <= 0) {
        pageNumber = 0;
      }

      const scrollContainer = that.$refs.scrollContainer;
      const tableRowHeight = 48;

      const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
      this.pageCount = Math.round(this.lessonListItems.length / itemsPerScreen);
      this.pageNumber = Math.round(pageNumber);
      this.setInfiniteScrollInitialData(openDate);
    } else {
      this.initialLoad = false;
      this.canLoadMore = false;
      this.canLoadMoreTop = false;
    }
  }

  refresh() {
    this.isExpanded = false;
  }

  initialize(openDate?: string, showAllUnits?:boolean) {
    this.lessonListItems = this.selectedLessonListLessons;
    if (showAllUnits) {
      this.selectedUnits = this.$t('allUnitsLabel');
    }
    if (this.viewType === 'L') {
      this.initializeInfiniteScroll(openDate);
    }
    // Lessons In Units Actions
    if (PlanbookStorage.get('unitViewLessonsParams')) {
      if (+PlanbookStorage.get('unitViewLessonsParams').subjectId !== this.currentSubjectId) {
        const that = this;
        CommonUtils.showLoading();
        that.changeClass(+PlanbookStorage.get('unitViewLessonsParams').subjectId, true)
          .then(() => {
            setTimeout(() => {
              that.filterUnits(that.units.filter(u => u.id === +PlanbookStorage.get('unitViewLessonsParams').unitId)[0]);
              PlanbookStorage.set('unitViewLessonsParams', undefined);
            }, 500);
          });
      } else {
        setTimeout(() => {
          this.filterUnits(this.units.filter(u => u.id === +PlanbookStorage.get('unitViewLessonsParams').unitId)[0]);
          PlanbookStorage.set('unitViewLessonsParams', undefined);
          CommonUtils.hideLoading();
        }, 500);
      }
    }
  }

  getUnitSelectBGColor(data:any) {
    if (data.unit && data.unit.unitColor) {
      return data.unit.unitColor;
    } else if (data.color) {
      return data.color;
    } else if (data.titleFillColor) {
      return data.titleFillColor;
    } else {
      return this.$vuetify.theme.currentTheme.primary;
    }
  }

  getUnitSelectColor(data:any) {
    if (+data.unitId > 0) {
      return this.isColorReadable('white', this.getUnitSelectBGColor(data)) ? 'white' : 'black';
    } else {
      return 'white';
    }
  }

  isColorReadable(fg: string, bg?: string) {
    return CommonUtils.isColorReadable(fg, bg);
  }

  isEmptyLesson(item:any) {
    return !this.hasLessonId(item);
  }

  hasLessonId(item:any) {
    if (+item.lessonId > 0) {
      return true;
    } else {
      return false;
    }
  }

  isUntitledLesson(item:any) {
    return this.hasLessonId(item) && CommonUtils.hasNoText(item.lessonTitle);
  }

  getValue(key: string, defaultValue: any) {
    if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
      return this.updatedSettings[key];
    }
    return defaultValue;
  }

  getDateBgColor(date:any) {
    if (date === this.currentDate) {
      return this.currentDateBackgroundColor;
    }
  }

  getDateTextColor(date:any) {
    if (date === this.currentDate) {
      return this.currentDateTextColor;
    }
  }

  showLessonActions(itemData: any) {
    return !(itemData.collaborateSubjectId > 0 && itemData.collaborateType === 1);
  }

  getLessonInput(itemData: any) {
    this.localEditingLesson = this.lessonUniqueIdentifier(itemData);
    const excludes = LessonServices.excludedLessonActions(itemData, this.userMode, this.localPage);
    return {
      exclude: excludes,
      loadData: true,
      data: itemData,
      lessonListsView: true
    }
  }

  showActions(event: any, itemData: any) {
    if (!this.showLessonActions(itemData)) {
      return;
    }
    this.localEditingLesson = this.lessonUniqueIdentifier(itemData);
    const excludes = LessonServices.excludedLessonActions(itemData, this.userMode, this.localPage);
    this.$eventBus.$emit('actions', {
      type: 'lesson',
      event,
      input: {
        exclude: excludes,
        loadData: true,
        data: itemData,
        lessonListsView: true
      }
    });
  }

  setInfiniteScrollInitialData(openDate?: string) {
    const that = this;
    this.fetchMoreItems(this.pageNumber, this.pageCount, true, openDate).then((items:any) => {
      this.infiniteScrollList.push(...items);
      this.initialLoad = false;
      // wait for initial list to render and then set up observer
      that.$nextTick(() => {
        const node:any = document.querySelector('.scroll-container .v-data-table__wrapper');
        if (that.currentDatePosition !== 0) {
          node.scrollTop = 50;
        }
        this.setUpInterSectionObserver();
        if (that.isAddingExtraLesson) {
          if (!that.autoSave) {
            that.openLessonViewMode(that.addedExtraLessonToOpen, false);
          }
          that.isAddingExtraLesson = false;
          that.addedExtraLessonToOpen = null;
          if (that.selectedUnits !== that.$t('allUnitsLabel')) {
            that.filterUnits(that.units.find(u => u.num === that.selectedUnits));
          }
        } else {
          const date = openDate || that.currentDate;
          const lessonToOpen = that.infiniteScrollList.find(i => i.date === date) || that.infiniteScrollList[0];
          if (!that.autoSave) {
            that.openLessonViewMode(lessonToOpen, false);
          }
          that.autoSave = false;
        }
      })
    })
      .catch((error:any) => {
        console.log('Reached end of page', error);
        this.initialLoad = false;
        this.canLoadMore = false;
        this.canLoadMoreTop = false;
      });
  }

  fetchMoreItems(pageNumber:any, pageCount:any, initialLoad:boolean, openDate?: string) {
    console.log(`fetchMoreItems(${pageNumber}, ${pageCount})`);
    const that = this;
    const scrollContainer = that.$refs.scrollContainer;
    const tableRowHeight = 48;

    let haveCurrentDate = false;
    let currentDatePosition = 0;

    const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));

    if (initialLoad) {
      this.lessonListItems.forEach((lessonList:any, index:number) => {
        if ((openDate && lessonList.date === openDate) || (!openDate && lessonList.date === this.currentDate)) {
          haveCurrentDate = true;
          currentDatePosition = index;
        }
      });
      if (this.isAddingExtraLesson) {
        haveCurrentDate = true;
        currentDatePosition = that.currentDatePosition;
      }
    }
    return new Promise((resolve, reject) => {
      const data:any = [];
      let indexOfLastData = pageNumber * 10;
      if (CommonUtils.isNotEmpty(that.infiniteScrollList)) {
        indexOfLastData = that.lessonListItems.findIndex((l:any) => {
          return that.lessonUniqueIdentifier(l) === that.lessonUniqueIdentifier(that.infiniteScrollList[that.infiniteScrollList.length - 1]);
        });
        indexOfLastData++;
      }
      let condition = indexOfLastData + itemsPerScreen;
      const offset = condition - currentDatePosition;
      if (initialLoad && offset !== itemsPerScreen) {
        condition = indexOfLastData + itemsPerScreen + (itemsPerScreen - offset);
      }
      if ((that.lessonListItems.length - indexOfLastData) < itemsPerScreen && !initialLoad) {
        for (let index = indexOfLastData; index <= that.lessonListItems.length - 1; index++) {
          data.push(that.lessonListItems[index]);
        }
      } else {
        for (let i = initialLoad && haveCurrentDate ? currentDatePosition : indexOfLastData; i < condition; i++) {
          if (that.lessonListItems[i] !== undefined) {
            data.push(that.lessonListItems[i]);
          }
        }
      }

      setTimeout(() => {
        if (CommonUtils.isNotEmpty(data)) {
          resolve(data);
        } else {
          reject(new Error('No more items to load at the Bottom'));
        }
      }, infiniteScrollDelay);
    });
  }

  fetchMoreItemsTop(pageNumber:any, pageCount:any) {
    console.log(`fetchMoreItemsTop(${pageNumber}, ${pageCount})`);
    const that = this;
    const scrollContainer = that.$refs.scrollContainer;
    const tableRowHeight = 48;
    return new Promise((resolve, reject) => {
      // page number is always 1,2,...10
      // list items are generated backwards
      const data:any = [];
      const indexOfFirstData = that.lessonListItems.findIndex((l:any) => {
        return that.lessonUniqueIdentifier(l) === that.lessonUniqueIdentifier(that.infiniteScrollList[0]);
      });

      if (indexOfFirstData - 1 < (Math.round(scrollContainer.clientHeight / tableRowHeight))) {
        for (let index = indexOfFirstData - 1; index >= 0; index--) {
          data.push(that.lessonListItems[index]);
        }
      } else {
        for (let i = indexOfFirstData - 1; i >= indexOfFirstData - (Math.round(scrollContainer.clientHeight / tableRowHeight)); i--) {
          data.push(that.lessonListItems[i]);
        }
      }

      setTimeout(() => {
        if (CommonUtils.isNotEmpty(data)) {
          resolve(data.reverse());
        } else {
          reject(new Error('No more items to load at the Top'));
        }
      }, infiniteScrollDelay);
    });
  }

  setUpInterSectionObserver() {
    const options = {
      root: this.$refs.scrollContainer,
      margin: '30px'
    };
    this.listEndObserver = new IntersectionObserver(
      this.handleIntersection,
      options
    );

    this.listEndObserverTop = new IntersectionObserver(
      this.handleIntersectionTop,
      options
    );

    this.listEndObserver.observe(this.$refs.sentinel);
    this.listEndObserverTop.observe(this.$refs.topSentinel);
  }

  handleIntersectionTop([entry]:any) {
    if (entry.isIntersecting) {
      console.log('sentinel intersecting');
    }
    if (entry.isIntersecting && this.canLoadMoreTop && !this.isLoadingMoreTop) {
      this.loadMoreTop();
    }
  }

  handleIntersection([entry]:any) {
    if (entry.isIntersecting) {
      console.log('sentinel intersecting');
    }
    if (entry.isIntersecting && this.canLoadMore && !this.isLoadingMore) {
      this.loadMore();
    }
  }

  isDayPast(day: any) {
    return DateTimeUtils.isPast(day.date);
  }

  async loadMoreTop() {
    try {
      this.isLoadingMoreTop = true;
      this.recordScrollPosition();
      const items:any = await this.fetchMoreItemsTop(this.pageNumber, this.pageCount);
      console.log('loaded page ' + this.pageNumber);

      // this.pageNumber--;
      // add items in the front
      this.infiniteScrollList.unshift(...items);
      this.isLoadingMoreTop = false;
      this.$nextTick(() => {
        this.restoreScrollPosition();
      });
    } catch (error) {
      console.log('Reached end of page Top', error);
      console.log('page ' + this.pageNumber)
      console.log('total items in the page ' + this.infiniteScrollList.length);
      this.canLoadMoreTop = false;
      this.isLoadingMoreTop = false;
    }
  }

  async loadMore() {
    try {
      this.isLoadingMore = true;
      const items:any = await this.fetchMoreItems(this.pageNumber, this.pageCount, false);
      console.log('loaded page ' + this.pageNumber);
      this.pageNumber++;
      this.infiniteScrollList.push(...items);
    } catch (error) {
      console.log('Reached end of page', error);
      console.log('total items in the page ' + this.infiniteScrollList.length);
      this.canLoadMore = false;
    } finally {
      this.isLoadingMore = false;
    }
  }

  recordScrollPosition() {
    const node:any = document.querySelector('.scroll-container .v-data-table__wrapper');
    this.previousScrollHeightMinusScrollTop = node.scrollHeight - node.scrollTop;
  }

  restoreScrollPosition() {
    const node:any = document.querySelector('.scroll-container .v-data-table__wrapper');
    node.scrollTop =
    node.scrollHeight - this.previousScrollHeightMinusScrollTop;
  }

  lessonTextField(item:any): string {
    let textField = '';
    for (const content of item.dayObject.sections) {
      if (content.hide === 'N' && content.type !== 'section-statuses') {
        for (const cItem of content.items) {
          if (CommonUtils.hasText(cItem.text)) {
            textField = this.firstHundredChar(cItem.text);
            break;
          }
        }
        if (CommonUtils.hasText(textField)) {
          break;
        }
      }
    }
    return textField;
  }

  unitSectionsTextField(item:any): string {
    let textField = '';
    if (item.unit) {
      for (const content of item.unit.contents) {
        if (CommonUtils.hasText(content.value)) {
          textField = this.firstHundredChar(content.value);
          break;
        }
      }
    }
    return textField;
  }

  linkedUnitSectionsTextField(item:any): string {
    let textField = '';
    if (item.linkedUnit) {
      for (const content of item.linkedUnit.contents) {
        if (CommonUtils.hasText(content.value)) {
          textField = this.firstHundredChar(content.value);
          break;
        }
      }
    }
    return textField;
  }
}
