
import { tableWidths } from '@/constants';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ld from 'lodash';
import CommonUtils from '@/utils/common-utils';
import { namespace } from 'vuex-class';
const settings = namespace('settings');

@Component
export default class ManageUserSchools extends Vue {
  @Prop({ type: Boolean, default: false })
  value!: boolean;

  userSchoolsToManage: Array<any> = [];

  @settings.Action
  connectTeacherSchools!: (params: any) => Promise<any>;

  get headers() {
    return [
      {
        text: this.$t('primaryLabel'),
        value: 'primary',
        width: tableWidths.shortText
      },
      { text: this.$t('schoolLabel'), value: 'schoolName' },
      { value: 'action', width: tableWidths.icon, sortable: false, align: 'right' }
    ];
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get isTrue() {
    return CommonUtils.isTrue;
  }

  get schoolsToSave() {
    return this.userSchoolsToManage.map(s => {
      return { schoolId: s.schoolId, primary: CommonUtils.isTrue(s.primary) }
    })
  }

  setToPrimary(school: any) {
    const clone = ld.cloneDeep(this.userSchoolsToManage);
    clone.forEach(s => {
      s.primary = CommonUtils.booleanToString(school.schoolId === s.schoolId);
    });
    this.userSchoolsToManage = clone;
  }

  removeSchool(school: any) {
    let clone = ld.cloneDeep(this.userSchoolsToManage);
    const isPrimary = CommonUtils.isTrue(school.primary);
    clone = clone.filter(s => school.schoolId !== s.schoolId);
    if (CommonUtils.isNotEmpty(clone) && isPrimary) {
      clone[0].primary = 'Y';
    }
    this.userSchoolsToManage = clone;
  }

  created() {
    this.userSchoolsToManage = this.$store.getters['settings/userSchoolsToManage'];
  }

  doApply() {
    CommonUtils.showLoading();
    this.connectTeacherSchools({ schools: JSON.stringify(this.schoolsToSave) }).finally(() => {
      this.localValue = false;
      CommonUtils.hideLoading();
    })
  }
}
