

























import SectionCard from '@/components/plans/card/SectionCard.vue';
import DayHeader from '@/components/plans/common/DayHeader.vue';
import PlansViewMixin from '@/mixins/plans-view-mixin';
import DateTimeUtils from '@/utils/date-time-utils';
import WebUtils from '@/utils/web-utils';
import ld from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  mixins: [PlansViewMixin],
  components: {
    SectionCard,
    DayHeader
  }
})
export default class VerticalView extends Vue {
  settings!: any;
  viewType!: string;
  expandNotesTodo!: boolean;
  currentPlans!: any;
  currentCyclePlans!: any;
  userMode!: string;
  showDetails!: boolean;
  browserWidth!: number;
  enabledDaysCount!: any;
  isHorizontal!: boolean;
  boxSize!: number;
  zoom!: number;
  bodyHeight!: number;
  headerOffset = 194;
  isReduceBrightnessOfPastDays!: boolean;
  getStyle!: (view: any) => any;
  isCurrent!: (view: any) => any;
  isMounted!: boolean;
  getAppBarBottomOffset!: () => number;

  get localPlans() {
    if (this.viewType === 'C') {
      return this.currentCyclePlans;
    } else {
      return this.currentPlans;
    }
  }

  get plans() {
    const plans = ld.cloneDeep(this.localPlans) || {};
    delete plans.dayCount;
    Object.keys(plans).forEach(key => {
      if (!key.startsWith('day')) {
        delete plans[key];
      } else {
        plans[key].past = DateTimeUtils.isPast(plans[key].date);
      }
    });
    return plans;
  }

  get height() {
    if (this.viewType === 'C') {
      return this.localBodyHeight / this.currentCyclePlans.dayCount;
    } else {
      return this.localBodyHeight / this.enabledDaysCount;
    }
  }

  get localBodyHeight() {
    if (this.isMounted) {
      return this.bodyHeight - this.getAppBarBottomOffset();
    } else {
      return this.bodyHeight - 64;
    }
  }

  get cardContainerHeightPx() {
    return (this.height / this.zoom) + 'px';
  }

  get cardWidthPx() {
    return (((this.browserWidth - 172) / +this.boxSize) / this.zoom) + 'px';
  }

  get heightPx() {
    return this.height + 'px';
  }

  getDayHeaderStyle(view: any) {
    const style = this.getStyle(view) || {};
    ld.merge(style, { height: this.heightPx });
    return style;
  }

  updated() {
    WebUtils.initMathJax();
  }
}
